import React, { useEffect, useState, useRef } from 'react'
// import { Overlay } from './Overlay'
import { Popup } from './Popup.tsx'
import {
  CertificationStatus,
  CidButtonLabel,
  CidButtonSize,
  CidButtonTheme,
  Claim,
  RequiredCertification,
} from './types.tsx'
import './global.css'
import './CidButton.css'
import { isRequiredClaimsValid } from './validator.tsx';
import config from '../../helpers/config'

// TODO validate params
type CidButtonProps = {
  onProceed: (authorisationServerId: string) => void
  claims: Claim[]
  requiredClaims?: Claim[]
  rpLogoUrl?: string
  requiredCertifications?: RequiredCertification[]
  label?: CidButtonLabel
  size?: CidButtonSize
  participantsUri?: string
  certificationStatus?: CertificationStatus
  rpDataSharingDescription?: string
  enableManualVerification?: boolean
  showAllParticipants?: boolean
  theme?: CidButtonTheme
}

export const CidButton: React.FC<CidButtonProps> = ({
  onProceed,
  claims,
  requiredClaims,
  rpLogoUrl,
  requiredCertifications,
  label = 'Verify',
  size = 'regular',
  participantsUri = config.cidParticipants,
  certificationStatus = 'Active',
  rpDataSharingDescription = 'Your details will be shared with the current website.',
  enableManualVerification = false,
  showAllParticipants = false,
  theme = 'dark',
}) => {
  const buttonRef = useRef<any>(null);
  const [showPopup, setShowPopup] = useState(false)

  const handleOnProceed = (authorisationServerId: string) => {
    setShowPopup(false)
    onProceed(authorisationServerId)
  }

  const handleButtonClick = () => {
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
  }

  useEffect(() => {
    buttonRef && buttonRef?.current?.click()
  },[buttonRef.current])

  if (!isRequiredClaimsValid(claims, requiredClaims)) {
    return (
      <React.Fragment>
        requiredClaims needs to be a subset of claims. claims: {claims.join(', ')}; requiredClaims:{' '}
        {requiredClaims?.join(', ')}.
      </React.Fragment>
    )
  }

  const ctaText = `${label} with ConnectID`

  return (
    <>
      {showPopup && (
        <React.Fragment>
          <Popup
            onProceed={handleOnProceed}
            onCancel={handleClosePopup}
            participantsUri={participantsUri}
            certificationStatus={certificationStatus}
            popupTitle={ctaText}
            requiredCertifications={requiredCertifications}
            rpLogoUrl={rpLogoUrl}
            rpDataSharingDescription={rpDataSharingDescription}
            claims={claims}
            requiredClaims={requiredClaims}
            enableManualVerification={enableManualVerification}
            showAllParticipants={showAllParticipants}
          />
        </React.Fragment>
      )}
      {!showPopup &&
        <button
          ref={buttonRef}
          className={`able-ActionButton able-ActionButton--high-emphasis fullwidth`}
          onClick={handleButtonClick}
          data-testid="cid-idp-selector-button"
        >
          <svg aria-hidden="true" focusable="false"  className="cid-idp-selector-button-icon" viewBox="0 0 132.37 157.84" fill="#fff">
            <path d="M102.22,91.65c0,19.9-16.13,36.03-36.03,36.03s-36.03-16.13-36.03-36.03,16.13-36.03,36.03-36.03c9.95,0,18.96,4.03,25.48,10.55l40.71-40.71c-6.52-6.52-10.55-15.52-10.55-25.46h-30.14c0,13.45,4.02,25.95,10.92,36.39-10.45-6.9-22.96-10.93-36.42-10.93C29.63,25.47,0,55.1,0,91.65s29.63,66.19,66.19,66.19,66.19-29.63,66.19-66.19h-30.16Z" />
          </svg>
          {ctaText}
        </button>
      }
    </>
  )
}
