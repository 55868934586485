import { clientErrors } from './messages';
import moment from 'moment';
const MIDDLE_NAME_PATTERN = new RegExp(/^[a-zA-Z\s'.-]{1,}$/);
const DRIVERS_LICENSE_PATTERN = new RegExp(/^[a-zA-Z0-9 ]{1,10}$/i);
const MEDICARE_CARD_STRICT_PATTERN = new RegExp(/^[a-zA-Z0-9\s\-']{1,}$/);
const AUS_PASSPORT_STRICT_PATTERN = new RegExp(/^[a-zA-Z\s\-']{1,}$/);
const NUMBER_PATTERN = new RegExp(/^[0-9]*$/);
const ALPHANUMERIC_PATTERN = new RegExp(/^[0-9A-Za-z]*$/);
const IMMICARD_PATTERN = new RegExp(/^[a-zA-Z]{3}[0-9]{6}$/);
const IDV_NAMES_STRICT_PATTERN = new RegExp(/(?=^.{1,50}$)^[a-zA-Z\s]+([\'\.\-]?[a-zA-Z\s]*)+([\'\.\-]?[a-zA-Z\s]*)?$/);
const IDV_NAMES_3_CONSECUTIVE_DUPLICATE_CHAR = new RegExp(/^(?!.*(.)\1\1)[a-zA-Z\s]+([\'\.\-]?[a-zA-Z\s]*)+([\'\.\-]?[a-zA-Z\s]*)?$/);
const INVALID_SEQEUNCE_PATTERN = new RegExp(/.*(--)+|(\.\.)+|(\.-)+|(-\.)+|(\.')+|('\.)+|('')+|(-')+|('-)+|(\s-)+|(-\s)+|('\s)+|(\s')+.*/);
const INVALID_TOKEN_PATTERN = new RegExp(
  /(\sMRS\s)|(MRS\s)|(\sMS\s)|(MISS\s)|(MS\s)|(\sACN\s)|(ACN\s)|(\sABN\s)|(ABN\s)|(\sDOB\s)|(DOB\s)|(\sINc\s)|(INC\s)|(\sLTD\s)|(LTD\s)|(\sMR\s)|(MR\s)|(\sPL\s)|(PL\s)|(\sPTY\s)|(PTY\s)|(\sREP\s)|(REP\s)|(\sSP\s)|(SP\s)|(INCORPORATED)|(NO\sLIABILITY)|(PROPRIETARY)|(ON\sBEHALF)|(PASSWORD)|(XXX)/i
);
const INVALID_ECOMM_PATTERN = new RegExp(/(?=^.{1,50}$)^(?!(MR|MRS|MS|MISS|-)?( ))(([a-zA-Z]['-])?(?!\w*(?: MRS | MS | ACN | ABN | DOB | INC | LTD | MR | PL | PTY | REP | SP |INCORPORATED|NO LIABILITY|PROPRIETARY|ON BEHALF|PASSWORD|XXX|  ))[a-zA-Z ])*(?<!\w*(?:PTYLTD))$/i);
const DRIVER_LICENECE = 'Driver Licence';
const AUS_PASSPORT = 'Australian Passport';
const INT_PASSPORT = 'International Passport';
const MEDICARE_CARD = 'Medicare Card';
const IMMI_CARD = 'Immi Card';

const validations = (name, value, values, flag) => {
  switch (name) {
    case 'firstName':
      return validateName(name,value,"GN")
    case 'middleName':
      return validateName(name,value,"MN")
    case 'lastName':
      return validateName(name,value,"FN")
    case 'familyName':
      return validateName(name,value,"FN")
    case 'dob':
      return validateDOB(value)
    case 'idType':
      return checkForValue(value,name)
    case 'stateOfIssue':
      return checkForValue(value,name)
    case 'licenceNumber':
      return validateDocNumber(value, values, name, flag)
    case 'cardNumber':
      return validateDocNumber(value, values,name, flag)
    case 'expiryDate':
      return validateDOE(value,flag)
    case 'documentNumber':
      return validateDocNumber(value, values,name, flag)
    case 'cardColor':
      return checkForValue(value,name)
    case 'issuingCountry':
      return checkForValue(value,name)
    case 'individualReferenceNumber':
      return positionNumber(value)
    case 'validNames':
      return validNamesMatch(values, flag)
    case 'date':
      return isValidDate(value)
    case 'isEmpty':
      return isEmpty(value)
    default:
      break;
  }
};

const isEmpty = (value) => {
  let valueIsEmpty = value === undefined || value === null || value === '' || value.trim() === '';
  return valueIsEmpty;
}

const namePatternValidate = (value) => {
  return INVALID_ECOMM_PATTERN.test(value);
}

const validateName = (name, valueName, errKey) => {
  let value = valueName.toUpperCase(); 
  let errors = {};
  const isMiddleName = name === "middleName" ? true : false;
  if (isMiddleName) {
    if (value.length !== 0) {
      if (value.length < 1 || value.length > 50) {
        errors.code = 'Name_invalid_length'
      } else if (validateAllNames(value,isMiddleName) && !validateNameDuplicateChar(value,isMiddleName)) {
        errors.code = 'Name_invalid_Duplicate'
      } else if (!validateAllNames(value,isMiddleName) || !namePatternValidate(value)) {
        errors.code = 'Name_invalid'
      }
    }
  } else {
    if (isEmpty(value) && !isMiddleName) {
      errors.code = errKey + '_empty'
    } else if ((value.length < 1 || value.length > 50)) {
      errors.code = 'Name_invalid_length'
    } else if (validateAllNames(value,isMiddleName) && !validateNameDuplicateChar(value,isMiddleName)) {
      errors.code = 'Name_invalid_Duplicate'
    } else if (!validateAllNames(value,isMiddleName) || !namePatternValidate(value)) {
      errors.code = 'Name_invalid'
    }
  }
  
  errors.msg = clientErrors[errors.code];
  return errors;
}

const validateAllNames = (nameValue, isMiddleName, allowNull = false) => {
  if (allowNull && !nameValue) {
      return true;
  } else if (nameValue == null) {
      return false;
  }
  const validNamePattern = validateNameForLicense(nameValue, isMiddleName);
  return validNamePattern;
}

const validateNameForLicense = (nameValue = '', isMiddleName) => {
  if (nameValue.length > 1) {
      return IDV_NAMES_STRICT_PATTERN.test(nameValue);
  } else if (isMiddleName && nameValue.length === 1) {
      return nameValue[0] === '.' || nameValue[0] === '-' || nameValue[0].match('^[a-zA-Z]');
  } else if (!isMiddleName && nameValue.length === 1) {
      return nameValue[0] === '.' || nameValue[0] === '-';
  }
  return true;
}

const validateNameDuplicateChar=(nameValue = '', isMiddleName)=> {
    const validNamePattern = validateNamePattern(nameValue);
    if (nameValue.length > 1) {
        return IDV_NAMES_3_CONSECUTIVE_DUPLICATE_CHAR.test(nameValue) && validNamePattern;
    } else if (isMiddleName && nameValue.length === 1) {
        return nameValue[0] === '.' || nameValue[0] === '-' || nameValue[0].match('^[a-zA-Z]');
    } else if (!isMiddleName && nameValue.length === 1) {
        return nameValue[0] === '.' || nameValue[0] === '-';
    }
    return false;
}

const validateNamePattern = (nameValue)=> {
  const containsValidSequence = !INVALID_SEQEUNCE_PATTERN.test(nameValue);
  const containsValidToken = !INVALID_TOKEN_PATTERN.test(nameValue);
  return containsValidSequence && containsValidToken;
}

const isValidDate = (dateString) => {
  const dateformat = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  // let dateformat = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
  if (dateString.match(dateformat)) {
    const operator = dateString.split('/');
    let datepart = [];
    if (operator.length > 1) {
      datepart = dateString.split('/');
    }
    if(datepart[0].length!==2 || datepart[1].length!==2 || datepart[2].length!==4) return false
    const day= parseInt(datepart[0]);
    const month = parseInt(datepart[1]);
    const year = parseInt(datepart[2]);
    // if(year < 1900) return false
    // Create list of days of a month
    const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year === 0 || month === 0 || month > 12 || day > 31 || day === 0) {
      return false;
    } else if (month === 1 || month > 2) {
      if (day > ListofDays[month - 1]) {
        return false;
      }
    } else if (month === 2) {
      let leapYear = false;
      if ((!(year % 4) && year % 100) || !(year % 400)) {
        leapYear = true;
      }
      if ((leapYear === false) && (day >= 29)) {
        return false;
      } else
        if ((leapYear === true) && (day > 29)) {
          // console.info('Invalid date format!');
          return false;
        }
    }
  } else {
    // console.info("Invalid date format!");
    return false;
  }
  return true;
}

const changeDateFormat = (dateString) => {
  let operator = dateString.split('/');
  let newDate = '';
  let datepart = [];
  if (operator.length > 1) {datepart = dateString.split('/');}
  let day= parseInt(datepart[0]);      
  let month = parseInt(datepart[1]);      
  let year = parseInt(datepart[2]);
  newDate = month + "/" + day + "/" + year;
  return newDate
} 

const checkDOB = (dob) => {
  const currentDate = moment();
  const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const minYears = '10';
  const maxYears = '120';
  const maxDate = currentDate.clone().subtract(minYears, 'years').format('YYYY-MM-DD');
  const minDate = currentDate.clone().subtract(maxYears, 'years').format('YYYY-MM-DD');
  if (!isEmpty(dob) && !moment(formattedDate).isBetween(minDate, maxDate, undefined, [])) {
      return false;
  }
  return true;
} 

const isMaximumYear = (dob)=> {
    const currentDate = moment();
    const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const maxYears = '120';
    const minDate = currentDate.clone().subtract(maxYears, 'years').format('YYYY-MM-DD');
    if (!isEmpty(dob) && !moment(formattedDate).isSameOrBefore(minDate)) {
        return false;
    }
    return true;
}

const isMinimumYear = (dob)=> {
    const currentDate = moment();
    const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const maxYears = '120';
    const minDate = currentDate.clone().subtract(maxYears, 'years').format('YYYY-MM-DD');
    if (moment(formattedDate).diff(minDate, 'days') <= 0) {
        return false;
    }
    return true;
}

const validateDOB = (value) => {
  let errors = {};
  if (isEmpty(value)) {
    errors.code = 'Date_empty'
  } else if (!isValidDate(value)) {
    errors.code = 'Date_invalid'
  } else if (!isMinimumYear(value)) {
    errors.code = 'DOB_MaxError'
  } else if (!checkDOB(value)) {
    errors.code = 'DOB_MinError'
  }
  errors.msg = clientErrors[errors.code];
  return errors;
}

const validateImmiCardNumber = (cardNumber) => {
  const sanitizedCardNumber = cardNumber.replace(/\s/g, '');
  if (!IMMICARD_PATTERN.test(sanitizedCardNumber)) {
    return false;
  }
  return true
}

const validateLicenseNumber = (licenseNumber, stateSelected = '') => {
  const sanitizedLicenseNumber = licenseNumber.replace(/\s/g, '');
  // if (isEmpty(stateSelected)) {
  //     return false;
  // }
  if (stateSelected === 'QLD') {
      // should contain only numbers; sanitizedLicenseNumber.match('^[0-9]{8,9}$')
      if (sanitizedLicenseNumber.length > 9 || sanitizedLicenseNumber.length < 8 || !NUMBER_PATTERN.test(sanitizedLicenseNumber)) {
        return false;
      }
    }
    if (stateSelected === 'TAS' || stateSelected === 'NSW') {
    // should contain only numbers and alphabets; sanitizedLicenseNumber.match('^[0-9A-Za-z]{6,8}$')
    if (sanitizedLicenseNumber.length <= 8 && sanitizedLicenseNumber.length >= 6) {
      return !!ALPHANUMERIC_PATTERN.test(sanitizedLicenseNumber);
      }
      return false;
    }
    if (stateSelected === 'SA') {
      // should contain only numbers and alphabets; sanitizedLicenseNumber.match('^[0-9A-Za-z]{6}$')
      if (sanitizedLicenseNumber.length !== 6 || !ALPHANUMERIC_PATTERN.test(sanitizedLicenseNumber)) {
          return false;
      }
  }
  if (stateSelected === 'ACT' || stateSelected === 'NT' || stateSelected === 'VIC') {
      // should contain only numbers
      if (!sanitizedLicenseNumber.match('^[0-9]{1,10}$')) {
          return false;
      }
  }
  if (stateSelected === 'WA') {
      // should contain only numbers; sanitizedLicenseNumber.match('^[0-9]{7}$')
      if (sanitizedLicenseNumber.length !== 7 || !NUMBER_PATTERN.test(sanitizedLicenseNumber)) {
          return false;
      }
  }
  return true;
}

const validateLicenseCardNumber=(dlCardNumber,stateSelected)=> {
  if (stateSelected === 'QLD') {
      if (dlCardNumber.match('^[a-zA-Z0-9]{10}$')) {
          return true;
      }
  } else if (stateSelected === 'TAS') {
      if (dlCardNumber.match('^[a-zA-Z0-9]{9}$')) {
          return true;
      }
  } else if (stateSelected === 'NSW') {
      if (dlCardNumber.match('^[0-9]{10}$')) {
          return true;
      }
  } else if (stateSelected === 'SA') {
      if (dlCardNumber.match('^[a-zA-Z0-9]{9}$')) {
          return true;
      }
  } else if (stateSelected === 'ACT') {
      if (dlCardNumber.match('^[a-zA-Z0-9]{10}$')) {
          return true;
      }
  } else if (stateSelected === 'NT') {
      if (dlCardNumber.match('^[0-9]{6,8}$')) {
          return true;
      }
  } else if (stateSelected === 'VIC') {
      if (dlCardNumber.match('^[a-zA-Z0-9]{8}$')) {
          return true;
      }
  } else if (stateSelected === 'WA') {
      if (dlCardNumber.match('^[a-zA-Z0-9]{8,10}$')) {
          return true;
      }
  }

  return false;
}

const validatePassport=(passportNumber)=> {
    if (passportNumber === null) {
        return false;
    }
    const sanitizedPassportNumber = passportNumber.replace(/\s\s+/g, '');
    if (!sanitizedPassportNumber.match('^[a-zA-Z]{1,2}[0-9]{7}$')) {
        return false;
    }
    return true;
}

const validateInternationalPassport=(passportNumber)=> {
    if (passportNumber === null) {
        // jshint ignore:line
        return false;
    }
    const sanitizedPassportNumber = passportNumber.replace(/\s\s+/g, '');
    if (!sanitizedPassportNumber.match('^[a-zA-Z0-9]{0,14}$')) {
        return false;
    }
    return true;
}

const validateMedicare=(medicareNumber)=> {
    if (medicareNumber == null) {
        return false;
    }
    const sanitizedMedicareNumber = medicareNumber.replace(/\s\s+/g, '');
    if (!sanitizedMedicareNumber.match('^[0-9]{10}$')) {
        return false;
    }
    return true;
}

const validateDocNumber = (value, values, name, idType) => {
  let errors = {};
  if (name === "documentNumber" && idType === DRIVER_LICENECE) {
    if (isEmpty(value)) {
      errors.code = 'DL_empty'
    } else if (!validateLicenseNumber(value,values.stateOfIssue) || /\s/.test(value)) {
      errors.code = 'DL_invalid'
    }
  } else if (name === "cardNumber") {
    if (isEmpty(value)) {
      errors.code = 'DL_card_empty'
    } else if (/\s/.test(value)) {
      errors.code = 'DL_card_invalid'
    } else if (idType === IMMI_CARD && !validateImmiCardNumber(value)) {
      errors.code = 'DL_card_invalid'
    } else if (idType !== IMMI_CARD && !validateLicenseCardNumber(value,values.stateOfIssue)) {
      errors.code = 'DL_card_invalid'
    } 
  } else if (name === "documentNumber") {
    if (isEmpty(value)) {
      errors.code = idType === MEDICARE_CARD ? 'MC_empty' : 'PP_Num_empty'
    } else if (idType === AUS_PASSPORT && (!validatePassport(value) || /\s/.test(value))) {
      errors.code = 'PP_Num_invalid'
    } else if (idType === INT_PASSPORT && (!validateInternationalPassport(value) || /\s/.test(value))) {
      errors.code = 'PP_Num_invalid'
    }  else if (idType === MEDICARE_CARD && (!validateMedicare(value) || /\s/.test(value))) {
      errors.code = 'MC_invalid'
    } 
  } 
  errors.msg = clientErrors[errors.code];
  return errors;
}

const checkDOE = (doe) => {
  const currentDate = moment();
  const formattedDate = moment(doe, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const minYears = '0';
  const maxYears = '10';
  const minDate = currentDate.clone().subtract(minYears, 'years').format('YYYY-MM-DD');
  const maxDate = currentDate.clone().add(maxYears, 'years').format('YYYY-MM-DD');
  if (!isEmpty(doe) && !moment(formattedDate).isBetween(minDate, maxDate, undefined, [])) {
      return false;
  }
  return true;
} 

const validateExpireDate = (expireDate, skipDay = false) => {
  const currentDate = skipDay ? moment().format('YYYY-MM') : moment().format('YYYY-MM-DD');
  const timeData = skipDay ? moment(expireDate, 'MM/YYYY').format('YYYY-MM') : moment(expireDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const valid = skipDay ? moment(timeData).isAfter(currentDate) : moment(timeData).isSameOrAfter(currentDate);
  if (!isEmpty(expireDate) && !valid) {
    return false;
  }
  return true;
}

const validateDOE = (value, idType) => {
  let errors = {};
  if (isEmpty(value)) {
    errors.code = 'Date_empty'
  } else if (!isValidDate(value)) {
    errors.code = 'Date_invalid'
  } else if (!validateExpireDate(value)) {
    errors.code = 'DOE_pastExpiry'
  } else if (!checkDOE(value)) {
    errors.code = 'DOE_postExpiry'
  }
  errors.msg = clientErrors[errors.code];
  return errors;
}

const checkForValue = (value, name) => {
  let errors = {};
  let eCode = "";
  if (name === "idType") {
    eCode = "Required_idType"
  } else if (name === "cardColor") {
    eCode = "Required_cardColor"
  } else if (name === "state") {
    eCode = "Required_stateOfIssue"
  } else if (name === "issuingCountry") {
    eCode = "Required_issuingCountry"
  } else {
    eCode = "Required_Field";
  }
  if (isEmpty(value)) {
    errors.code = eCode
  }
  errors.msg = clientErrors[errors.code];
  return errors;
}

const checkCardNumber = (value, values, cardType, name) => {
  let errors = {};
  let regEx = /^(?=.*[A-Za-z0-9])(?=.*[0-9])[A-Za-z0-9]+$/;
  let eCode = "Required_documentNumber";
  if (value.length === 0) {
    if (name === "licenceNumber") {
      if (values.idType === DRIVER_LICENECE) {
        eCode = "Required_documentNumber_DL";
      } else if (values.idType === "Medicare Card" || cardType === "medic") {
        eCode = "Required_documentNumber";
      } else {
        eCode = "Required_documentNumberPP";
      }
    } else {
      eCode = "Required_cardNumber";
    }
    errors.code = eCode;
  } else if (value.length > 0) {
    if (/^0*$/.test(value)) {
      errors.code = 'Invalid_Card'
    } else if (cardType === "medic") {
      if (value.trim().length !== 10 || !/^[0-9]+$/.test(value)) {
        errors.code = 'Invalid_MedicCard'
      }
    } else if (value.trim().length < 6 || value.trim().length > 15) {
      errors.code = 'Invalid_Card'
    } else if (!/[A-Za-z0-9]/.test(value) || !/\d/.test(value)) { 
      errors.code = 'Invalid_Card'
    } else if (!regEx.test(value) && !/^[0-9]*$/.test(value)) {
      errors.code = 'Invalid_Card'
    }
  }
  errors.msg = clientErrors[errors.code];
  return errors;
}


const isNameValid = (value) => {
  let isValid = true;
  if (!value || value.length === 0) { 
    isValid = false;
  } else if (value.trim().length < 2) {
    isValid = false;
  } else if (!/[a-zA-Z]/.test(value)) {
    isValid = false
  } else {
    isValid = /^[a-zA-Z'.-\s]+$/.test(value)
  }
  return isValid
}

const validNamesMatch = (values,idType) => {
  let isPassport = (idType && idType === "AUSTRALIAN PASSPORT") ? true : false;
  let fn = values.firstName ? values.firstName : '';
  let ln = values.familyName ? values.familyName : '';
  if (isPassport && isNameValid(ln)) {
    return false
  } else if ((!isNameValid(fn) && !isNameValid(ln)) || (isNameValid(fn) && !isNameValid(ln)) || (!isNameValid(fn) && isNameValid(ln))) {
    return true
  } else {
    return false
  }
}

const validateNames = (value) => {
  let errors = {};
  if (value.length > 0 && !isNameValid(value)) {
    errors.code = 'Invalid_Name'
  }
  errors.msg = clientErrors[errors.code];
  return errors;
}

// validate DOB is not lessthan 18years to current date
const validateDateOfBirth = (dateString, over10 = false, Under_18 = false) => {
  let operator = dateString.split('/');
  let datepart = [];
  if (operator.length > 1) {
    datepart = dateString.split('/');
  }
  let day= parseInt(datepart[0]);      
  let month = parseInt(datepart[1]);      
  let year = parseInt(datepart[2]);
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const d = new Date().getDate();
  let isValid = true;
  // let a = (new Date() - new Date(year, month - 1, day)) / (365.25 * 24 * 60 * 60 * 1000);
  if (new Date(year+125, month - 1, day) < new Date(y,m,d)) {
    isValid = false;
  } else if (over10 && new Date(year + 10, month - 1, day) > new Date(y,m,d)) {
    isValid = false;
  } else if(Under_18 && new Date(y,m,d) < new Date(year + 18, month - 1, day)){
    isValid = false;
  }
  return isValid;
}

// validate DOE is not lessthan today
const validateDateOfExpiry = (dateString, isDL = false) => {
  let operator = dateString.split('/');
  let datepart = [];
  if (operator.length > 1) {
    datepart = dateString.split('/');
  }
  let day= parseInt(datepart[0]);
  let month = parseInt(datepart[1]);
  let year = parseInt(datepart[2]);
  let isValid = true;
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const d = new Date().getDate();
  if (new Date(year, month - 1, day) < new Date(y, m, d)) {
    isValid = false;
  }
  if (isDL === "s" && new Date(year - 10, month - 7, day) > new Date(y, m, d)) {
    isValid = false;
  }
  if (isDL === "n" && new Date(year - 10, month - 1, day) > new Date(y, m, d)) {
    isValid = false;
  }
  return isValid;
}
// validate DOE is not lessthan today
const positionNumber = (value) => {
  let errors = {};
  if (value.length === 0) {
    errors.code = 'Required_PN'
  } else if (!/[0-9]/g.test(value)) {
    errors.code = 'Invalid_Pnum'
  } else if (!/^([1-9])$/.test(value)) {
  // } else if (!/^([1-9]|10|11)$/.test(value)) {
    errors.code = 'Invalid_Pnum'
  }
  errors.msg = clientErrors[errors.code];
  return errors;
}

const validateMedic = (dateString) => {
  let dateformat = /^([0-9]{2})\/([0-9]{4})$/;
  if (dateString.match(dateformat)) {
    let operator = dateString.split('/');
    let datepart = [];
    if (operator.length > 1) {
      datepart = dateString.split('/');
    }
    if(datepart[0].length!==2 || datepart[1].length!==4) return false
    let month = parseInt(datepart[0]);
    let year = parseInt(datepart[1]);
    if (year < 1900) return false
    if (month > 12) {
      return false;
    }
  } else {
    return false;
  }
  return true;  
}

const validateMedicYear = (dateString) => {
  let dateformat = /^([0-9]{2})\/([0-9]{4})$/;
  if (dateString.match(dateformat)) {
    let operator = dateString.split('/');
    let datepart = [];
    if (operator.length > 1) {
      datepart = dateString.split('/');
    }
    if(datepart[0].length!==2 || datepart[1].length!==4) return false
    if(parseInt(datepart[0]) === 0 || parseInt(datepart[1]) === 0 || parseInt(datepart[0]) > 12) return false
  } else {
    return false;
  }
  return true;  
}

const validateDateOfExpiryMedic = (dateString, limitYear = 10,flag=false) => {
  let operator = dateString.split('/');
  let datepart = [];
  if (operator.length > 1) {
    datepart = dateString.split('/');
  }
  let month = parseInt(datepart[0]);
  let year = parseInt(datepart[1]);
  let isValid = true;
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const d = new Date().getDate();
  if (new Date(year, month-1,d) < new Date(y,m,d)) {
    isValid = false;
  }
  if(flag && new Date(year - limitYear, month - 1, d) > new Date(y, m, d)) {
    isValid = false;
  }
  return isValid;  
}


export default validations;