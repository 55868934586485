import constants from "../helpers/constants.json";
export default function importInputKeys(key) {
  let defaultKeys = [];
  if (key && key === "DRIVER_LICENCE") {
    defaultKeys = [
      {
        id: "stateOfIssue",
        title: "State",
        type: "select",
        options: constants.stateList,
        error: "Required filed"
      },
      {
        id: "documentNumber",
        title: "Licence number",
        type: "text",
        error: "Please enter valid licence number",
        showHelpTxt: true,
        helpText: "The licence number is between 1 and 10 letters and numbers."
      },
      {
        id: "cardNumber",
        title: "Card number",
        type: "text",
        showCardRef: true,
        error: "Please enter valid card number"
      },
      {
        id: "expiryDate",
        title: "Expiry date",
        type: "date",
        error: "Please enter valid date of expiry",
        helpText: "Use the format DD MM YYYY. Enter numbers only."
      },
    ];
  } else if (key && key === "AUSTRALIAN_PASSPORT") {
    defaultKeys = [
      {
        id: "documentNumber",
        title: "Passport number",
        type: "text",
        error: "Please enter valid passport number"
      },
      {
        id: "expiryDate",
        title: "Expiry Date",
        type: "date",
        error: "Please enter valid date of expiry",
        helpText: "Use the format DD MM YYYY. Enter numbers only."
      },
    ];
  } else if (key && key === "INTERNATIONAL_PASSPORT") {
    defaultKeys = [
      {
        id: "documentNumber",
        title: "Passport number",
        type: "text",
        error: "Please enter valid passport number"
      },
      {
        id: "issuingCountry",
        title: "Issuing Country",
        type: "select",
        options: constants.countries,
        error: "Required filed"
      },
      {
        id: "expiryDate",
        title: "Expiry Date",
        type: "date",
        error: "Please enter valid date of expiry",
        helpText: "Use the format DD MM YYYY. Enter numbers only."
      }
    ];
  } else if (key && key === "IMMI_CARD") {
    defaultKeys = [
      {
        id: "cardNumber",
        title: "Card number",
        type: "text",
        showCardRef: false,
        error: "Please enter valid card number",
      }
    ];
  } else if (key && key === "MEDICARE_CARD") {
    defaultKeys = [
      {
        id: "documentNumber",
        title: "Document number",
        type: "text",
        error: "Please enter valid Medicare number",
        vCard: "medic"
      },
      {
        id: "individualReferenceNumber",
        title: "Position number",
        type: "select",
        options: constants.referenceNumber,
        error: "Required Field"
      },
      {
        id: "cardColor",
        title: "Card color",
        type: "select",
        options: constants.cardColor,
        error: "Required Field"
      },
      {
        id: "expiryDate",
        title: "Expiry Date",
        type: "date",
        error: "Please enter valid date of expiry",
        vCard: "medic",
        helpText: "Use the format DD MM YYYY. Enter numbers only."
      },
    ];
  } else {
    defaultKeys = [];
  }
  return defaultKeys;
}
