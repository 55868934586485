// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import useSWR from 'swr'
import { CertificationStatus, Claim, Participant, RequiredCertification } from './types.tsx'
import { Skeleton } from './Skeleton.tsx'
import { filterAuthorisationServers, getManualAuthorisationServer } from './certification.tsx'
import './global.css'
import './Popup.css'
import { TextStyle, Spacing, ActionButton } from '@able/react';
import CustomSpacing from "../../components/Spacing";

type PopupProps = {
  onProceed: (authorisationServerId: string) => void
  onCancel: () => void
  claims: Claim[]
  requiredClaims?: Claim[]
  showAllParticipants: boolean
  participantsUri: string
  certificationStatus: CertificationStatus
  popupTitle: string
  rpDataSharingDescription: string
  enableManualVerification: boolean
  requiredCertifications?: RequiredCertification[]
  rpLogoUrl?: string
}

export const Popup: React.FC<PopupProps> = ({
  onProceed,
  onCancel,
  claims,
  requiredClaims,
  showAllParticipants,
  participantsUri,
  certificationStatus,
  popupTitle,
  rpDataSharingDescription,
  enableManualVerification,
  requiredCertifications,
  rpLogoUrl,
}) => {
  const [showClaims, setShowClaims] = useState(false) // mobile only
  const {
    data: participants,
    error,
    isLoading,
  } = useSWR(participantsUri, async () => {
    const response = await fetch(participantsUri)
    if (!response.ok) throw new Error(response.statusText)
    return response.json() as Promise<Participant[]>
  })
  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    popupRef.current?.focus();
  }, [])

  const claimsOrder: Record<Claim, number> = {
    name: 1,
    given_name: 2,
    middle_name: 3,
    family_name: 4,
    birthdate: 5,
    email: 6,
    address: 7,
    phone_number: 8,
    over18: 9,
    over21: 10,
    over25: 11,
    over65: 12,
    beneficiary_account_au: 13,
    beneficiary_account_au_payid: 14,
    beneficiary_account_international: 15,
  }

  const claimsDescription: any = {
    address: 'Residential address',
    birthdate: 'Date of birth',
    email: 'Email address',
    family_name: 'Family name',
    given_name: 'Given name',
    middle_name: 'Middle name',
    name: 'Full name',
    phone_number: 'Phone number',
    over18: '18+ verification',
    over21: '21+ verification',
    over25: '25+ verification',
    over65: '65+ verification',
    beneficiary_account_au: 'Beneficiary account',
    beneficiary_account_au_payid: 'PayID',
    beneficiary_account_international: 'International account',
  }

  const sortClaims = (claims: Claim[]) => {
    const sortedClaims = [...claims]
    return sortedClaims.sort((claim1, claim2) => claimsOrder[claim1] - claimsOrder[claim2])
  }

  const handleEnterEsc = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key !== 'Enter' && event.key !== 'Escape') return

    if (event.key === 'Escape') {
      onCancel()
      return
    }

    // Enter
    const activeElement = document.activeElement
    if (!activeElement) return

    if (activeElement.classList.contains('cid-idp-selector-popup-participant')) {
      ;(activeElement as HTMLElement).click()
    }
  }

  const renderAuthorisationServers = () => {
    if (isLoading) {
      return (
        <TextStyle element='div'>
          <div className="cid-idp-selector-popup-skeleton-container">
            <Skeleton height="160px" />
          </div>
        </TextStyle>
      )
    }

    if (error) {
      return (
        <TextStyle element='div'>
          <TextStyle element='h3' alias='HeadingC'>Choose a provider</TextStyle>
          <TextStyle element='div' className="infobox">
            <TextStyle element='h4' alias='Label'>We were not able to load Identity Providers.</TextStyle>
              <TextStyle>Come back to us later.</TextStyle>
          </TextStyle>
        </TextStyle>
      )
    }

    const authorisationServers = participants!
      .map((participant:any) => participant!.AuthorisationServers as any)
      .flat()
      .sort((authServer1, authServer2) =>
        authServer1.CustomerFriendlyName.localeCompare(authServer2.CustomerFriendlyName)
      )

    const manualAuthorisationServer = getManualAuthorisationServer(authorisationServers, showAllParticipants)
    const authorisationServersWithoutManual = authorisationServers.filter(
      ({ AuthorisationServerId }) => AuthorisationServerId !== manualAuthorisationServer?.AuthorisationServerId
    )

    const filterByClaims = requiredClaims || claims
    const filteredAuthorisationServers = filterAuthorisationServers(
      authorisationServersWithoutManual,
      showAllParticipants,
      certificationStatus,
      filterByClaims,
      requiredCertifications
    )

    const hasOnlyManualAuthorisationServer = manualAuthorisationServer && filteredAuthorisationServers.length === 0

    // if there's only one IDP and it's a manual one (speficic situation for launch - might remove later)
    if (enableManualVerification && hasOnlyManualAuthorisationServer) {
      return (
        <div className="cid-idp-selector-popup-provider-wrapper">
          <div className="cid-idp-selector-popup-manualonly">
            <div className="cid-idp-selector-popup-manualonly-info">
              {/* card icon */}
              <svg
                aria-hidden="true" focusable="false" 
                className="cid-idp-selector-popup-manualonly-icon"
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_401_12266)">
                  <mask id="mask0_401_12266" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="42">
                    <path d="M42 0H0V42H42V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_401_12266)">
                    <path
                      d="M30 0H12C5.37258 0 0 5.37258 0 12V30C0 36.6274 5.37258 42 12 42H30C36.6274 42 42 36.6274 42 30V12C42 5.37258 36.6274 0 30 0Z"
                      fill="#EFFBF4"
                    />
                    <path
                      d="M35 9H12C10.3431 9 9 10.3431 9 12V28C9 29.6569 10.3431 31 12 31H35C36.6569 31 38 29.6569 38 28V12C38 10.3431 36.6569 9 35 9Z"
                      fill="#BEEDD2"
                    />
                    <path
                      d="M31.9996 11.8496H8.99961C7.25991 11.8496 5.84961 13.2599 5.84961 14.9996V27.9996C5.84961 29.7393 7.25991 31.1496 8.99961 31.1496H31.9996C33.7393 31.1496 35.1496 29.7393 35.1496 27.9996V14.9996C35.1496 13.2599 33.7393 11.8496 31.9996 11.8496Z"
                      fill="#F9FDFB"
                      stroke="#262626"
                      strokeWidth="1.7"
                    />
                    <path d="M30.5 18H23" stroke="#BEEDD2" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M30.5 21H23" stroke="#BEEDD2" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M30.5 24H23" stroke="#BEEDD2" strokeWidth="1.5" strokeLinecap="round" />
                    <path
                      d="M15 21C16.1046 21 17 20.1046 17 19C17 17.8954 16.1046 17 15 17C13.8954 17 13 17.8954 13 19C13 20.1046 13.8954 21 15 21Z"
                      stroke="#262626"
                      strokeWidth="1.2"
                    />
                    <path
                      d="M19 26C19 24.9391 18.5786 23.9217 17.8284 23.1716C17.0783 22.4214 16.0609 22 15 22C13.9391 22 12.9217 22.4214 12.1716 23.1716C11.4214 23.9217 11 24.9391 11 26H19Z"
                      stroke="#262626"
                      strokeWidth="1.2"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_401_12266">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div>
                <h3 className="cid-idp-selector-popup-manualonly-title">
                  You can verify your details with ConnectID using your own identity document.
                </h3>
                <p className="cid-idp-selector-popup-manualonly-description">
                  ConnectID does not collect, hold, or store any personal information at any time.
                </p>
              </div>
            </div>
            <button
              className="cid-idp-selector-popup-manualonly-button"
              onClick={() => onProceed(manualAuthorisationServer!.AuthorisationServerId)}
              tabIndex={1}
            >
              Confirm your identity with a document
            </button>
          </div>
        </div>
      )
    }

    // there a risk here when there's only manual IDP and manual verification is not enabled -> no providers available
    return (
      <React.Fragment>
        <CustomSpacing top='spacing4x' bottom={undefined} left={undefined} right={undefined}>
          <TextStyle element='div'>
            <TextStyle element='h3' alias='HeadingB'>Choose a provider</TextStyle>
            <CustomSpacing top='spacing2x' bottom={undefined} left={undefined} right={undefined} children={undefined}/>
            {filteredAuthorisationServers.length > 0 ? (
              <TextStyle element='div' className='serversGrid'>
                {filteredAuthorisationServers.map(
                  ({ AuthorisationServerId, CustomerFriendlyLogoUri, CustomerFriendlyName }) => (
                      <button
                        type='button'
                        data-testid="participant"
                        className="cid-idp-selector-popup-participant able-ActionButton able-ActionButton--medium-emphasis fullwidth"
                        onClick={() => onProceed(AuthorisationServerId)}
                        key={AuthorisationServerId}
                        title={CustomerFriendlyName}
                      >
                        <img
                          className="cid-idp-selector-popup-participant-icon"
                          src={CustomerFriendlyLogoUri}
                          alt={`${CustomerFriendlyName} logo`}
                        />
                        <TextStyle className="cid-idp-selector-popup-participant-name">
                          {CustomerFriendlyName}
                        </TextStyle>
                      </button>
                  )
                )}
              </TextStyle>
            ) : (
              <CustomSpacing top='spacing4x' bottom={undefined} left={undefined} right={undefined}>
                <TextStyle element='div' className='infobox'>
                  <TextStyle element='h4' alias='HeadingC'>No Identity Providers available.</TextStyle>
                  <CustomSpacing top='spacing2x' bottom={undefined} left={undefined} right={undefined} children={undefined}/>
                  <TextStyle>Come back to us later.</TextStyle>
                </TextStyle>
              </CustomSpacing>
            )}
          </TextStyle>
        </CustomSpacing>
        {enableManualVerification && manualAuthorisationServer && (
          <CustomSpacing top='spacing4x' bottom={undefined} left={undefined} right={undefined}>
              <TextStyle>
              Can't see a provider that works for you?
              </TextStyle>
                <button
                  className="cid-idp-selector-popup-manual-action"
                  tabIndex={1}
                  onClick={() => onProceed(manualAuthorisationServer.AuthorisationServerId)}
                  data-testid="manual-verification-action"
                >
                  Confirm your identity with a document
                </button>
              <TextStyle>
                ConnectID does not collect, hold or store your personal information at any time.
              </TextStyle>
          </CustomSpacing>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <TextStyle element='div'>
        <TextStyle>
          <svg aria-hidden="true" focusable="false"  className="cid-idp-selector-popup-logo" viewBox="0 0 132.37 157.84">
            <path d="M102.22,91.65c0,19.9-16.13,36.03-36.03,36.03s-36.03-16.13-36.03-36.03,16.13-36.03,36.03-36.03c9.95,0,18.96,4.03,25.48,10.55l40.71-40.71c-6.52-6.52-10.55-15.52-10.55-25.46h-30.14c0,13.45,4.02,25.95,10.92,36.39-10.45-6.9-22.96-10.93-36.42-10.93C29.63,25.47,0,55.1,0,91.65s29.63,66.19,66.19,66.19,66.19-29.63,66.19-66.19h-30.16Z" />
          </svg>
        </TextStyle>
      </TextStyle>
      <CustomSpacing top='spacing2x' bottom='spacing2x' left={undefined} right={undefined}>
        <TextStyle element="h1" alias="HeadingB">{popupTitle || "testing"}</TextStyle>
      </CustomSpacing>
      <TextStyle>{rpDataSharingDescription || "Your details will be shared with the current website."}</TextStyle>
      {claims && (
        <CustomSpacing  top='spacing2x' bottom={undefined} left={undefined} right={undefined}>
          <CustomSpacing bottom='spacing2x' top={undefined} left={undefined} right={undefined}>
            <TextStyle element="h2" alias='HeadingC'>Details requested</TextStyle>
          </CustomSpacing>
          <CustomSpacing top='spacing1x' bottom={undefined} left={undefined} right={undefined}>
          <TextStyle element="div" className='claimsList'>
            {sortClaims(claims).map((claim) => (
              <React.Fragment key={claim}>
                <TextStyle>
                  <svg aria-hidden="true" focusable="false"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                      fill="#BEEDD2"
                    />
                    <path
                      d="M4.81445 8.14121L6.95437 10.2811L11.472 5.76465"
                      stroke="#262626"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                    />
                  </svg>
                  {claimsDescription[claim]}
                </TextStyle>
              </React.Fragment>
            ))}
            </TextStyle>
          </CustomSpacing>
        </CustomSpacing>
      )}
      {
        renderAuthorisationServers()
      }
      <CustomSpacing top='spacing4x' bottom={undefined} left={undefined} right={undefined}>
        <TextStyle element='div' className='infobox'>
          <CustomSpacing bottom='spacing2x' top={undefined} left={undefined} right={undefined}>
            <TextStyle element='h3' alias='HeadingC'>What is ConnectID?</TextStyle>
          </CustomSpacing>
          <TextStyle>
            ConnectID is an Australian-owned digital identity solution which allows you to securely prove who you are.
            ConnectID does not see or store your personal data. Visit below link to find out more..
          </TextStyle>
          <ActionButton
            developmentUrl={window.location.origin + '/able-sprites.svg'}
            element="a"
            label="Visit connectid.com.au"
            to="/"
            type="button"
            variant="LowEmphasis"
          />
        </TextStyle>
      </CustomSpacing>
    </React.Fragment>
  )
}
