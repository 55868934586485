import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Column, Spacing, TextStyle, ActionButton, Picto, Success104 } from '@able/react';
import PageContent from "../PageContent";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { getSession } from "../../helpers";
import CustomSpacing from "../../components/Spacing";

export default function PageSuccess(props) {
  const navigation = useNavigate();
  const titleRef = useRef(null);
  const [titleText] = useState("Thank you!");

  const handleOnClick = (eventName) => {
    updateAnalyticsData(titleText, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": eventName
      },
      "attributes":{}
    })
    if (getSession("gotoUrl")) window.location = getSession("gotoUrl")
    else navigation("/",{ replace: true });
  }
  
  useEffect(() => { 
    const ariaHidden = document.getElementById("root");
    ariaHidden.hasAttribute("aria-hidden") && ariaHidden.removeAttribute("aria-hidden");
    document.querySelector("body").style.overflow = "auto";
    setTimeout(() => {
      if (getSession("gotoUrl")) window.location = getSession("gotoUrl")
    }, 5000);
  },[])
  
  return (
    <PageContent title={titleText} className={"whiteBg"}>
      <Grid>
        <Column cols={12}>
          <CustomSpacing bottom="spacing4x" className="errorImgBlock">
            <Picto theme="blue">
              <Success104 />
            </Picto>
          </CustomSpacing>
          <PageTitle bottom="spacing1x" title={titleText} ref={titleRef} focusTitle={true} />
          <TextStyle element="h3" alias="HeadingC">ID verified successfully</TextStyle>
          <CustomSpacing top="spacing3x" bottom="spacing5x">
            <TextStyle>
              Please wait while we redirect you to PPA screen. or click below button to redirect.
            </TextStyle>
          </CustomSpacing>
          <ActionButton
            developmentUrl={window.location.origin + '/able-sprites.svg'}
            element="button"
            icon="Refresh"
            type="button"
            label="Redirect"
            to="/"
            variant="LowEmphasis"
            onClick={() => handleOnClick("Redirect")}
          />
        </Column>
      </Grid>
    </PageContent>
  );
}
