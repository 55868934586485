import getSession from "../helpers/sessions";

export function getFetchConfigForDocument(token=false,parToken=false) {
  const access_token = getSession('AuthData');
  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': access_token ? `Bearer ${access_token.access_token}` : `Bearer ${token}`,
  }
  if (parToken) {
    headers = {
      ...headers,
      "auth_server_id": getSession("par_authorisationServerId"),
      "originalState": getSession("par_state"),
      "code_verifier": getSession("par_verifier"),
      "nonce": getSession("par_nonce")
    }
  }
  return {
    mode: "cors",
    credentials: 'same-origin',
    headers: headers
  }
}

function handleCSRFHeader(response) {
  const csrfHeaderKey = response.headers.get("X-CSRF-HEADER");
  if (csrfHeaderKey) {
    _csrtHeaderKey = csrfHeaderKey;
    _csrtHeaderValue = response.headers.get(csrfHeaderKey);
  }
  return response;
}

export function handleRawResponse(response) {
  handleCSRFHeader(response);

  if (response.ok || (response.status >= 400 && response.status < 500)) {
    return response.json();
  }

  return Promise.reject(new Error(`${response.status} ${response.statusText}`));
}

export function handleJsonResponse(response, isRawResponseOk) {
  if (response.message && !isRawResponseOk) {
    throw new Error(response.message);
  } else {
    return response;
  }
}




