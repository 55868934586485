import React from "react";
import { Grid, Spacing, Divider } from "@able/react";
import Logo from "../../assets/logo.svg";
import CustomSpacing from "../../components/Spacing";

function TelstraLogo() {
  return (
    <header className="header-comm">
      <Grid>
        <CustomSpacing top="spacing1x" bottom="spacing1x" >
          <div className="t-logo-mobile">
            <img src={Logo} alt="Telstra Logo" aria-hidden="true" focusable="false" />
          </div>
        </CustomSpacing>
      </Grid>
      <Divider/>
    </header>
  )
}

export default React.memo(TelstraLogo);