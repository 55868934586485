import { store } from "../store";
import { handleRawResponse, getFetchConfigForDocument } from ".";
import config from "../helpers/config";
import { getSession } from "../helpers";

const apiBaseUrl = config.apiHost + config.apiBase;
const isAuthenticated = getSession("isAuthenticated");
const apiController = isAuthenticated ? "auth/" : "unauth/";

export const validateIDVRequest = () => dispatch => {
  const controller = new AbortController();
  let promise = null; 
  const token = getSession("user_token");
  const request = {
    "token": token,
  }
  setTimeout(() => {
    controller.abort();
  }, 30000);
  promise = fetch(
    apiBaseUrl + apiController + 'validate-request',
    // 'https://slot2.org002.t-dev.corp.telstra.com/presentation/v1/idverification/unauth/validate-request',
    {
    ...getFetchConfigForDocument(token),
    method: "POST",
    body: JSON.stringify(request),
  }).then(handleRawResponse);
  
  return promise.then(response=>{
    return response;
  })
}

export const validateIDVDocument = (data) => dispatch => {
  const controller = new AbortController();
  let promise = null; 
  const token = getSession("user_token");
  setTimeout(() => {
    controller.abort();
  }, 30000);
  promise = fetch(
    apiBaseUrl + apiController + 'documents-verify',
    // 'https://slot2.org002.t-dev.corp.telstra.com/presentation/v1/idcheck/user/auth/documents-verify',
    {
    ...getFetchConfigForDocument(token),
    method: "POST",
    body: JSON.stringify(data),
  }).then(handleRawResponse);
  
  return promise.then(response=>{
    return response;
  })
}

export const IDPSelectorAuth = (data) => dispatch => {
  const controller = new AbortController();
  let promise = null; 
  setTimeout(() => {
    controller.abort();
  }, 30000);
  promise = fetch(
    // 'https://slot2.org002.t-dev.corp.telstra.com/presentation/v1/idcheck/user/auth/par',
    apiBaseUrl + 'auth/par',
    {
    ...getFetchConfigForDocument(),
    method: "POST",
    body: JSON.stringify(data),
  }).then(handleRawResponse);
  
  return promise.then(response=>{
    return response;
  })
}

export const IDPSelectorAuthToken = (cid_code , cid_state, cid_iss) => dispatch => {
  const controller = new AbortController();
  let promise = null; 
  setTimeout(() => {
    controller.abort();
  }, 30000);
  promise = fetch(
    apiBaseUrl + 'auth/partoken?code=' + cid_code + '&state=' + cid_state + '&iss=' + cid_iss,
    {
    ...getFetchConfigForDocument(true),
    method: "GET",
  }).then(handleRawResponse);
  
  return promise.then(response=>{
    return response;
  })
}

