import React from 'react'
import './Skeleton.css'

type SkeletonProps = {
  height: string
  width?: string
  background?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({ height, width = '100%', background = 'whitesmoke' }) => {
  return <div className="cid-idp-selector-skeleton" style={{ height, width, background }} />
}
