/* eslint-disable */
import { getEnv } from './analytics';
import { getSession, setSession } from "../helpers";

var env = getEnv(window.location.host);
env = env ? env : 'local';
let mainURL = window.location.origin + window.location.pathname;
mainURL = mainURL.replace(/\/$/, "");
export const goToUrlRegex = /^(?:https|myt|myboost):\/\/[\x21\x23-\x3b\x3d\x3f-\x5b\x5d\x5f\x61-\x7b\x7d\x7e]+$/

const qs = window.location.search;
if (qs) {
  let params = new URLSearchParams(qs);
  if (params.get("gotoUrl") && goToUrlRegex.test(params.get("gotoUrl"))) {
    setSession("gotoUrl", params.get("gotoUrl"))
  }
}

const local = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: 'http://localhost:3000',
  apiHost:'https://slot2.org002.t-dev.telstra.com/',
  apiBase: 'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au',
  auth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  },
  unAuth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  }
};

const uat = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://slot2.org002.t-dev.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au',
  auth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  },
  unAuth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  }
};

const fn2 = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://slot2.org002.t-dev.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au',
  auth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  },
  unAuth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  }
};

const prod = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://tapi.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.connectid.com.au/participants',
  auth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  },
  unAuth: {
    validateIDVReq: '',
    dvsVerify: '',
    parRequest: '',
    parToken: '',
    cancelIDV: ''
  }
};

const config = (env === 'devel' || env === 'uat') ? uat : env === 'fn2' ? fn2 : env === 'production' ? prod : local;

export default { ...config };