import React from "react";
import { TextStyle, Spacing } from "@able/react";
import CustomSpacing from "../components/Spacing";
const states = [
  {id:"ACT",front:"ACT"},
  {id:"NSW",front:"NSW"},
  {id:"SA",front:"SA",back:"SA_back"},
  {id:"NT",front:"NT",back:"NT_back"},
  {id:"QLD",front:"QLD",back:"QLD_back"},
  {id:"TAS",front:"TAS",back:"TAS_back"},
  {id:"VIC",front:"VIC",back:"VIC_back"},
  {id:"WA",front:"WA",back:"WA_back"},
]
const getSampleImage = (type) => {
  return (
    <React.Fragment>
      { !type ? <TextStyle>Please select State to see sample image for card number</TextStyle> :
        states.map((el, index) => {
          if (el.id === type) {
            return (
              <React.Fragment key={index}>
                <TextStyle>You'll find your details on the front and/or back of your driver licence.</TextStyle>
                <CustomSpacing top="spacing2x"/>
                <img src={window.location.origin+'/sample/'+el.front+'.svg'} alt={type} aria-hidden="true" focusable="false"  className="img-responsive" />
                {el.back &&
                  <CustomSpacing top="spacing2x">
                    <img src={window.location.origin+'/sample/'+el.back+'.svg'} alt={type} aria-hidden="true" focusable="false" className="img-responsive" />
                  </CustomSpacing>
                }
              </React.Fragment>
            )
          }
        })
      }
      
    </React.Fragment>
  )
};

export default getSampleImage;
