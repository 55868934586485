import { getEnv } from '../helpers/analytics';
var env = getEnv(window.location.host);
env = env ? env : 'local';

export class localConstants {
  public static stsAuthority = 'https://fn1.myid-nonprod.telstra.com/identity';
  public static clientRoot = 'http://localhost:3000/';
  public static logout = 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=http://localhost:3000/';
  public static apiRoot = 'https://fn1.myid-nonprod.telstra.com/identity/';
}

export class uatConstants {
  public static stsAuthority = 'https://fn1.myid-nonprod.telstra.com/identity';
  public static clientRoot = 'https://fn1.nonprod.idcheck.telstra.com/';
  public static logout = 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn1.nonprod.idcheck.telstra.com';
  public static apiRoot = 'https://fn1.myid-nonprod.telstra.com/identity/';
}

export class fn2Constants {
  public static stsAuthority = 'https://fn1.myid-nonprod.telstra.com/identity';
  public static clientRoot = 'https://fn2.nonprod.idcheck.telstra.com/';
  public static logout = 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn2.nonprod.idcheck.telstra.com';
  public static apiRoot = 'https://fn1.myid-nonprod.telstra.com/identity/';
}
export class prodConstants {
  public static stsAuthority = 'https://myid.telstra.com/identity';
  public static clientRoot = 'https://idcheck.telstra.com/';
  public static logout = 'https://myid.telstra.com/identity/idp/startSLO.ping?TargetResource=https://idcheck.telstra.com';
  public static apiRoot = 'https://myid.telstra.com/identity/';
}

export const sendSettings = (inputData: any) => {
  return {
    'authority': inputData.stsAuthority,
    'client_id': 'b2c-idcheck',
    'redirect_uri': `${inputData.clientRoot}signin-callback.html`,
    'silent_redirect_uri': `${inputData.clientRoot}silent-renew.html`,
    'post_logout_redirect_uri': `${inputData.logout}`,
    'response_type': 'code',
    'scope': 'openid',
    'automaticSilentRenew': true,
    'prompt': 'none',
  }
}

export const sendTokenURL = (inputData: any) => {
  return inputData.apiRoot;
}

export const loadConstantBasedOnEnv = () => {
  if (env === 'devel' || env === 'uat') {
    return uatConstants
  } else if (env === 'production') {
    return prodConstants
  } else if (env === 'fn2') {
    return fn2Constants
  } else {
    return localConstants
  }
}