import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Column,
  Spacing,
  TextStyle,
  ActionButton,
  MessageInline,
  Surface,
  Divider,
  InlineLink,
  Lozenge,
  Accordion,
  AccordionItem
} from "@able/react";
import CustomSpacing from "../../components/Spacing";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { setSession, getSession } from "../../helpers";
import icon1 from "../../assets/icon-1.png";
import icon2 from "../../assets/icon-2.svg";
const privacy_link = "https://www.telstra.com.au/privacy#info-collect";
const alternate_link = "https://www.telstra.com.au/support/account-payment/id-check-for-new-account";
const contact_link = "https://www.telstra.com.au/contact-us";

export default function PageConfirmIdentity() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const [title] = useState("Confirm your identity");
  const [isLoading, setIsLoading] = useState(true);
  const {validateIDVResponse} = useSelector((state) => state.verifyIdentify)

  //console.log("verifyIdentify",validateIDVResponse)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  },[])
  
  // handling click events
  const handleOnClick = (id, eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": eventName
      },
      "attributes":{}
    })
    if (id === "connectID") {
      console.log("connectID")
      alert("ConnectID button clicked")
      //navigation("/digital-id",{ replace: true });
    } else {
      console.log("verify-identity");
      navigation("/verify-identity",{ replace: true});
    }
  }

  return (
    <PageContent title={title}>
      {isLoading ? <Loader />
        :
        <Grid padding={false}>
          <Column cols={12}>
            <PageTitle ref={titleRef} title={title} focusTitle={true} />
            <CustomSpacing top="spacing1x" bottom="spacing3x">
              <TextStyle>You can verify your identity via one of the following:</TextStyle>
            </CustomSpacing>
            <CustomSpacing bottom="spacing3x">
              <Lozenge label='Option 1' variant='Neutral'/>
            </CustomSpacing>
            <TextStyle alias="HeadingC" element="h2">Verify with ConnectID</TextStyle>
            <CustomSpacing top="spacing1x" bottom="spacing3x">
              <TextStyle>
                ConnectID is a secure way to verify your identity using the information held by an organisation you already trust. 
              </TextStyle>
            </CustomSpacing>
            <CustomSpacing bottom="spacing2x">
              <Surface
                background="materialBaseSecondary"
                cornerRadius="cornerRadiusSoft"
                variant="SurfaceFlat"
              >
                <CustomSpacing
                  bottom="spacing2x"
                  left="spacing3x"
                  right="spacing3x"
                  top="spacing2x"
                >
                  <React.Fragment key=".0">
                    <TextStyle className="t-text-center">Digital ID providers you can use:</TextStyle>
                    <TextStyle className="t-text-center images-group">
                      <img src={icon1} alt="" aria-hidden="true" focusable="false" />
                      <img src={icon2} alt="" aria-hidden="true" focusable="false" />
                    </TextStyle>
                  </React.Fragment>
                </CustomSpacing>
              </Surface>
            </CustomSpacing>
            <CustomSpacing top="spacing3x" bottom="spacing3x">
              <button
                className="fullwidth able-ActionButton able-ActionButton--high-emphasis cid-idp-selector-button"
                onClick={() =>
                  handleOnClick("connectID", "Verify with ConnectID")
                }
              >
                <svg viewBox="0 0 132.37 157.84" aria-hidden="true" focusable="false" >
                  <path d="M102.22,91.65c0,19.9-16.13,36.03-36.03,36.03s-36.03-16.13-36.03-36.03,16.13-36.03,36.03-36.03c9.95,0,18.96,4.03,25.48,10.55l40.71-40.71c-6.52-6.52-10.55-15.52-10.55-25.46h-30.14c0,13.45,4.02,25.95,10.92,36.39-10.45-6.9-22.96-10.93-36.42-10.93C29.63,25.47,0,55.1,0,91.65s29.63,66.19,66.19,66.19,66.19-29.63,66.19-66.19h-30.16Z"></path>
                </svg>
                Verify with ConnectID
              </button>
            </CustomSpacing>
            <Accordion variant="Contained">
              <AccordionItem
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                label="What is ConnectID?"
              >
                <TextStyle>
                  By choosing to verify manually, you allow Telstra to collect and store your ID details, such as your full name, date of birth, and ID card number. 
                </TextStyle>
              </AccordionItem>
              <AccordionItem
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                label="How does ConnectID work?"
              >
                <TextStyle>
                  By choosing to verify manually, you allow Telstra to collect and store your ID details, such as your full name, date of birth, and ID card number. 
                </TextStyle>
              </AccordionItem>
            </Accordion>
            <CustomSpacing top="spacing4x" bottom="spacing5x">
              <Divider/>
            </CustomSpacing>
            <Lozenge label='Option 2' variant='Neutral' />
            <CustomSpacing top="spacing3x" bottom="spacing1x">
            <TextStyle alias="HeadingC" element="h3">Verify manually </TextStyle>
            </CustomSpacing>
            <TextStyle>
              By choosing to verify manually, you allow Telstra to collect and store your ID details, such as your full name, date of birth, and ID card number. 
            </TextStyle>
            <CustomSpacing top="spacing3x" bottom="spacing3x">
              <ActionButton
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                element="button"
                label="Enter your ID details"
                to="/"
                type="button"
                className="fullwidth"
                variant="MediumEmphasis"
                onClick={() =>
                  handleOnClick("manual", "Enter your ID details")
                }
              />
            </CustomSpacing>
            <TextStyle>ID documents we accept: </TextStyle>
            <CustomSpacing top="spacing3x" bottom="spacing3x" left="spacing2x">
              <TextStyle element="ul">
                <li>Australian Driver's Licence</li>
                <li>Australian or International Passport </li>
                <li>Medicare card</li>
                <li>Immi card</li>
              </TextStyle>
            </CustomSpacing>
            <InlineLink>
              <TextStyle alias="FinePrint">
                  Telstra collecting your personal information in order to comply with its regulatory obligations, including Australian Communications and Media Authority (ACMA) rules on ID check for prepaid mobile. Information about how we collect, store, use and share personal information can be found in our <a href={privacy_link} target="_blank" rel="noreferrer" >Privacy Statement.</a>
              </TextStyle>
            </InlineLink>
            <CustomSpacing top="spacing3x">
              <InlineLink>
                <TextStyle alias="FinePrint">
                    Having trouble verifying your ID? You can verify your identity in store using <a href={alternate_link} target="_blank" rel="noreferrer" >alternative forms of ID</a> or <a href={contact_link} target="_blank" rel="noreferrer" >Contact us.</a>
                </TextStyle>
              </InlineLink>
            </CustomSpacing>
          </Column>
        </Grid>
      }
    </PageContent>
  );
}