import React, {useState, useEffect, useRef} from "react";
import { Grid, Column, TextStyle, Picto, SomethingWentWrong104 } from '@able/react';
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import CustomSpacing from "../../components/Spacing";
import TelstraHeader from "../TelstraLogo";
import TelstraFooter from "../TelstraFooter";
import initAnalytics from '../../helpers/analytics';

export default function PageRestricted(props) {
  const titleRef = useRef(null);
  const [titleText] = useState("Oops!");
  
  useEffect(() => {
    if (!window.digitalData) initAnalytics();
    updateAnalyticsData(titleText, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "technical issue",
        "eventCategory": "",
        "eventName": "Access restricted"
      },
      "attributes": {}
    })
  }, []);
  
  return (
    <div id="page-content">
      <TelstraHeader />
      <main className="page-container">
        <CustomSpacing top="spacing5x" bottom="spacing5x">
          <Grid>
            <Column cols={12}>
              <CustomSpacing bottom="spacing4x" className="errorImgBlock">
                <Picto theme="blue">
                  <SomethingWentWrong104 />
                </Picto>
              </CustomSpacing>
              <PageTitle title={titleText} ref={titleRef} focusTitle={true} />
              <TextStyle alias="HeadingC" element="h3">Something is not right</TextStyle>
              <CustomSpacing top="spacing3x" bottom="spacing1x">
                <TextStyle>
                  We've looked everywhere but cannot complete your request.
                </TextStyle>
              </CustomSpacing>
                <TextStyle>
                  Please try again
                </TextStyle>
            </Column>
          </Grid>
        </CustomSpacing>
      </main>
      <TelstraFooter />
    </div>
  );
}
