export const errorRefId = {
  prefix: `REF`,
  delimeter: `-`,
  validateIDVRequest: `VR`,
  validateIDVDocument: `VD`,
};

export const clientErrors = {
  Required_Field: `Required field.`,
  GN_empty : 'Required field. Please enter your given name.',
  MN_empty : 'Required field. Please enter your middle name.',
  FN_empty : 'Required field. Please enter your family name.',
  Name_invalid : 'Invalid input. Please use alphabetic characters only.',
  Name_invalid_length : 'Invalid input. Name must be 2 characters or more.',
  Name_invalid_Duplicate : 'Invalid input. Please enter your name only, avoiding titles, acronyms and post-noms.',
  Date_empty : 'Required field. Please use format DD/MM/YYYY.',
  Date_invalid: 'Invalid input. Please use format DD/MM/YYYY.',
  DOB_invalid: 'Invalid input. Please enter a valid date of birth.',
  DOB_MinError: 'The minimum age is 10 years. Please enter a valid date of birth.',
  DOB_MaxError: 'Invalid input. Please enter a valid date of birth.',
  DOE_GreenCard: 'Invalid input. Please use format MM/YYYY.',
  DOE_pastExpiry: "Invalid input. Please enter a valid expiry date (can't be a past date).",
  DOE_postExpiry: "Invalid input. Please enter a valid expiry date (can't be more than 10 years).",
  Select_empty: 'Required field. Please select an option.',
  State_empty: 'Required field. Please select a state of issue.',
  PP_Num_empty: 'Required field. Please enter your passport number.',
  PP_Num_invalid: 'Invalid input. Please enter a valid passport number.',
  DL_empty: 'Required field. Please enter your licence number.',
  DL_invalid: 'Invalid input. Please enter a valid licence number.',
  DL_card_empty: 'Required field. Please enter your card number.',
  DL_card_invalid: 'Invalid input. Please enter a valid card number.',
  MC_empty: 'Required field. Please enter your Medicare card number.',
  MC_invalid: 'Invalid field. Please enter a valid Medicare card number.',
  



  Invalid_NameLN: `Required. Please enter family name.`,
  Required_PN: `Required. Please enter position number.`,
  Required_idType: `Required. Please select ID type.`,
  Required_cardColor: `Required. Please select card color.`,
  Required_stateOfIssue: `Required. Please select a state.`,
  Required_issuingCountry: `Required. Please select country.`,
  Required_cardNumber: `Required. Please enter card number.`,
  Required_documentNumber: `Required. Please enter document number.`,
  Required_documentNumber_DL: `Required. Please enter licence number.`,
  Required_documentNumberPP: `Required. Please enter your passport number.`,
  Required_expiryDate: `Required. Please enter expiry date.`,
  Required_dob: `Required. Please enter date of birth.`,
  Invalid_Date: `Entered year should not be less than 1900.`,
  Invalid_DateDOE: `This document is expired, check the expiry date or try another document.`,
  Invalid_Date_Format: `Invalid entry. Please enter valid date.`,
  Future_Date: `Future dates are not allowed.`,
  Date_Range: `The range must be seven days apart only.`,
  Start_Date_Greater: `The start date must come before the end date.`,
  Invalid_Startdate: `The start date provided is not valid, please enter a valid date.`,
  Invalid_Enddate: `The end date provided is not valid, please enter a valid date.`,
  No_Reports: `Only last 14 days scans are available for viewing.`,
  Invalid_Name: `Invalid entry. Your name must only contain the following characters: A-Z,- dot (.) or hyphen (-) or apostrophe (').`,
  Invalid_Card: `Please enter valid number`,
  Invalid_MedicCard: `In-valid entry. Card number must have 10 digits.`,
  Doc_Expired: `This document is expired, check the expiry date or try another document.`,
  Doc_Greater: `The document's expiry date cannot be greater than 10 years 6 months.`,
  Doc_Greater_10: `The document's expiry date cannot be greater than 10 years.`,
  Doc_Greater_5: `The document's expiry date cannot be greater than 5 years.`,
  Below_10: `Customer is under the age of 10 and cannot proceed.`,
  Over_125: `Customer is over the age of 125 and cannot proceed.`,
  Under_18: `Customer is under the age of 18. Do not offer any postpaid services.`,
  Invalid_Pnum: `Invalid entry. Position number must contain only numbers between 1 to 9`,
  Future_Year: `Invalid entry. Date of birth cannot be a future date.`,
  Past_Year: `Invalid entry. Date of expiry cannot be a past date.`,
};

export const serverErrors = {
  err_default: {
    code: "err_default",
    heading: `Oops!`,
    subHeading: `Something is not right`,
    content1: `We've looked everywhere but cannot complete your request.`,
    content2: `Please try again.`,
    errDesc: `Please try again.`,
    errRefNo: "000",
    redirectTo: "ERR"
  },
  validateIDVRequest: {
    err_400_900: {
      code: "err_400_900",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `COMMON_ERROR: An exception occurred due to service unavailable or object un intialized`,
      errRefNo: "001",
      redirectTo: "ERR"
    },
    err_400_901: {
      code: "err_400_901",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `AUTHORIZATION_MISSED: Missing input parameter`,
      errRefNo: "002",
      redirectTo: "ERR"
    },
    err_400_902: {
      code: "err_400_902",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `CERTIFICATE_CONVERT_ERROR: Loading the certificate and key in to the key store is failed`,
      errRefNo: "003",
      redirectTo: "ERR"
    },
    err_400_903: {
      code: "err_400_903",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `GENERATE_CERTIFICATE_SUMMARY: Certificate conversion has been failed`,
      errRefNo: "004",
      redirectTo: "ERR"
    },
    err_400_904: {
      code: "err_400_904",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `KEYSTORE_ERROR: Keystore build failed`,
      errRefNo: "005",
      redirectTo: "ERR"
    },
    err_400_905: {
      code: "err_400_905",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `X_GATEWAY_HOST_MISSED: Missing request header x-gateway-host for method parameter of type String`,
      errRefNo: "006",
      redirectTo: "ERR"
    },
    err_400_906: {
      code: "err_400_906",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `when body/token in body is null: Missing body information.`,
      errRefNo: "007",
      redirectTo: "ERR"
    },
    err_400_907: {
      code: "err_400_907",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if user flow is unauthn;header security token is missed: Missing Authorization Bearer information`,
      errRefNo: "008",
      redirectTo: "ERR"
    },
    err_400_908: {
      code: "err_400_908",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if user flow is unauthn;header security token and request body token is mismatched: Params provided in header and body are mismatched.`,
      errRefNo: "009",
      redirectTo: "ERR"
    },
    err_400_909: {
      code: "err_400_909",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if unable to decode the token provided in the req body: Unable to decode token. Provided token could be tampered.`,
      errRefNo: "010",
      redirectTo: "ERR"
    },
    err_400_910: {
      code: "err_400_910",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `lookup IDV_REQUESTS based on token Id ,if records not found: No records found for supplied token/referenceId`,
      errRefNo: "011",
      redirectTo: "ERR"
    },
    err_500_4000: {
      code: "err_500_4000",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if unable to connect/ fetch/save/update DB: Error occured while connecting to DB server`,
      errRefNo: "012",
      redirectTo: "ERR"
    },
    err_422_1050: {
      code: "err_422_1050",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `lookup IDV_REQUESTS based on token Id ,if records found and DvsStatus !=null: Transaction has already completed,unable to proceed further`,
      errRefNo: "013",
      redirectTo: "gotoUrl"
    },
    err_422_1010: {
      code: "err_422_1010",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `lookup IDV_REQUESTS based on token Id ,if records found and token Id expired: IdvRequest has expired.`,
      errRefNo: "014",
      redirectTo: "gotoUrl"
    },
    err_422_1020: {
      code: "err_422_1020",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if user flow is authn;CAIMAN session username & username in found record is mismatched: Params found in CAIMAN session are not matching with IDV Request.`,
      errRefNo: "015",
      redirectTo: "gotoUrl"
    },
  },
  validateIDVDocument: {
    err_400_900: {
      code: "err_400_900",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `COMMON_ERROR: An exception occurred due to service unavailable or object un intialized`,
      errRefNo: "001",
      redirectTo: "ERR"
    },
    err_400_901: {
      code: "err_400_901",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `AUTHORIZATION_MISSED: Missing input parameter`,
      errRefNo: "002",
      redirectTo: "ERR"
    },
    err_400_902: {
      code: "err_400_902",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `CERTIFICATE_CONVERT_ERROR: Loading the certificate and key in to the key store is failed`,
      errRefNo: "003",
      redirectTo: "ERR"
    },
    err_400_903: {
      code: "err_400_903",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `GENERATE_CERTIFICATE_SUMMARY: Certificate conversion has been failed`,
      errRefNo: "004",
      redirectTo: "ERR"
    },
    err_400_904: {
      code: "err_400_904",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `KEYSTORE_ERROR: Keystore build failed`,
      errRefNo: "005",
      redirectTo: "ERR"
    },
    err_400_905: {
      code: "err_400_905",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `X_GATEWAY_HOST_MISSED: Missing request header x-gateway-host for method parameter of type String`,
      errRefNo: "006",
      redirectTo: "ERR"
    },
    err_400_911: {
      code: "err_400_911",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if scanType is other than Digital: Supplied scanType is not valid`,
      errRefNo: "007",
      redirectTo: "ERR"
    },
    err_400_912: {
      code: "err_400_912",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if provided names  are not matched with provided regex pattern: Provided names should met with acceptance criteria`,
      errRefNo: "008",
      redirectTo: "ERR"
    },
    err_400_913: {
      code: "err_400_913",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if clientCode is b2c-prepaidactivation then  check DOB format(d/M/yyyy) / DOE is in invalid format(d/M/yyyy): Provided DOB (or) DOE should be in valid format`,
      errRefNo: "009",
      redirectTo: "ERR"
    },
    err_400_914: {
      code: "err_400_914",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if clientCode is b2c-prepaidactivation & DOB is in valid format then check DOB validation for <10 & >120: Invalid DOB, Either over the age of 120 (or) under the age of 10`,
      errRefNo: "010",
      redirectTo: "ERR"
    },
    err_400_915: {
      code: "err_400_915",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if provided Idtype is not valid: Supplied idType is not valid`,
      errRefNo: "011",
      redirectTo: "ERR"
    },
    err_400_916: {
      code: "err_400_916",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if provided state of issue is not valid / if idtype is Australian passport,issuing country is other than AUS: Supplied StateOfIssue (or) IssuingCountry is not valid`,
      errRefNo: "012",
      redirectTo: "ERR"
    },
    err_400_917: {
      code: "err_400_917",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if doc number/cardnumber/referencenumber is not matched with regex pattern: Provided DocumentNumber (or) CardNumber (or) IndividualReferenceNumber should  met with acceptance criteria`,
      errRefNo: "013",
      redirectTo: "ERR"
    },
    err_400_918: {
      code: "err_400_918",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `DOE is valid but less than today's date: Invalid DOE, This Document Has Expired`,
      errRefNo: "014",
      redirectTo: "ERR"
    },
    err_400_919: {
      code: "err_400_919",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if medicare card is other than Y,B,G: Color Restrictions Applied for the provided document`,
      errRefNo: "015",
      redirectTo: "ERR"
    },
    err_422_1040: {
      code: "err_422_1040",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if it's a safe customer when user not have an option to edit the PD details but the details provided in request and DB are mismatched: For this user editing of Personal details is not allowed`,
      errRefNo: "016",
      redirectTo: "gotoUrl"
    },
    err_422_1030: {
      code: "err_422_1030",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if token provided in header and token Id in DB are mismatched: For this transaction, Supplied request is not matching with tokenId in DB`,
      errRefNo: "017",
      redirectTo: "gotoUrl"
    },
    err_422_1100: {
      code: "err_422_1100",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `CONNECTION_TIMEOUT: CONNECTION_TIMEOUT(message from the exception)`,
      errRefNo: "018",
      redirectTo: "gotoUrl"
    },
    err_422_1110: {
      code: "err_422_1110",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `BAD_GATEWAY / GATEWAY_TIMEOUT: 502/504 Bad Gateway: OKAPI received an error from the proxied upstream server`,
      errRefNo: "019",
      redirectTo: "gotoUrl"
    },
    err_422_1200: {
      code: "err_422_1200",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `MULTIPLE_VID: Multiple VIDs Found for this Customer and hence unable to process the request`,
      errRefNo: "020",
      redirectTo: "gotoUrl"
    },
    err_422_1210: {
      code: "err_422_1210",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `400 Bad request/WRONG _VID: FamilyName did not match validation (or) Verification Id is not Valid / Active`,
      errRefNo: "021",
      redirectTo: "gotoUrl"
    },
    err_422_1220: {
      code: "err_422_1220",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `CONSENT_REQUEST: Consent not captured.Consent must be given by the contact before submitting the request.`,
      errRefNo: "022",
      redirectTo: "gotoUrl"
    },
    err_422_1300: {
      code: "err_422_1300",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `DVS_DOWN(500): Error while calling Green ID / Error while retrieving documents`,
      errRefNo: "023",
      redirectTo: "gotoUrl"
    },
    err_422_8000: {
      code: "err_422_8000",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `got some unknown exception in client: Dynamic exception`,
      errRefNo: "024",
      redirectTo: "gotoUrl"
    },
    err_422_1010: {
      code: "err_422_1010",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `lookup IDV_REQUESTS based on token Id ,if records found and token Id expired: IdvRequest has expired.`,
      errRefNo: "025",
      redirectTo: "gotoUrl"
    },
    err_422_1020: {
      code: "err_422_1020",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `if user flow is authn;CAIMAN session username & username in found record is mismatched: Params found in CAIMAN session are not matching with IDV Request.`,
      errRefNo: "026",
      redirectTo: "gotoUrl"
    },
    err_422_2100: {
      code: "err_422_2100",
      heading: `Oops!`,
      subHeading: `Something is not right`,
      content1: `We've looked everywhere but cannot complete your request.`,
      content2: `Please try again.`,
      errDesc: `MAX_ATTEMPTS: max attempts(3) reached with IDV verify api.`,
      errRefNo: "027",
      redirectTo: "gotoUrl"
    },
  }
};