import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Column,
  InlineLink,
  TextStyle,
  Select,
  ActionButton,
  Checkbox,
  TextField,
  DayMonthYearGroup,
  RadioButton,
  RadioGroup,
  ModalContent,
  useToggle,
  MessageSection,
  MessageInline,
  ModalDialogCriticalDecision
} from "@able/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { setSession, getSession } from "../../helpers";
import idSelection from "../../helpers/constants.json";
import getSampleImage from "../../helpers/sampleImage";
import validations from "../../helpers/validations";
import importInputKeys from "../../components/importIDkeys";
import { validateIDVDocument } from '../../redux/api';
import { serverErrors, errorRefId } from "../../helpers/messages";
import { saveIDVDocResponse, setCommonError } from '../../redux/verifyIdentifySlice';
import CustomSpacing from "../../components/Spacing";
let BAMBOO_IDV_UNAVAILABLE;
const editInputs = {
  firstName: "given name",
  middleName: "middle name",
  familyName: "family name",
  dob: "date of birth",
}
const authenticatedInputs = {
  firstName: "Test",
  middleName: "no name",
  familyName: "Jhon",
  dob: "11/11/2000",
}

export default function PageVerifyIdentity() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const radioGroupRef = useRef(null);
  const inputRef = useRef({});
  const buttonRef = useRef({});
  const [modalIsOpen, toggleModal] = useToggle();
  const [isEditModel, toggleEditModal] = useToggle();
  const [removeModalIsOpen, setRemoveModal] = useToggle();
  const currentYear = new Date().getFullYear();
  const [title] = useState("Verify your identity");
  const [isLoading, setIsLoading] = useState(true);
  const [isSingleName, setIsSingleName] = useState(false);
  const [profileInputs, setProfileInputs] = useState(idSelection.profile);
  const [selectIdType, setSelectIdType] = useState("");
  const [documentKeys, setDocumentKeys] = useState([]);
  const [documentInputs, setDocumentInputs] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState({});
  const [expiryDate, setExpiryDate] = useState({});
  const [errors, setErrors] = useState({});
  const [focusErrElement, setFocusErrElement] = useState(false);
  const [isNameUpdated, setIsNameUpdated] = useState({ status: false, desc: "Your family name will be updated once your ID is verified.", icon: "Success" });
  const [editbleName, setEditableName] = useState({ key: "", title: "", value: "" });
  const [isAuthenticated, setIsAuthenticated] = useState(getSession("isAuthenticated"));
  const [isProfileEdited, setIsProfileEdited] = useState(false);
  const [dvsAttempts, setDvsAttempts] = useState({ status: false, text: "" });
  const [dvsCalls, setDvsCalls] = useState(3);
  const { validateIDVResponse, isPageLoaded, IDVDocResponse } = useSelector((state) => state.verifyIdentify);

  useEffect(() => {
    if (!getSession("dvsCalls") || getSession("dvsCalls") === 0) {
      setSession("dvsCalls", 3)
      setDvsCalls(3)
    } else {
      setSession("dvsCalls", getSession("dvsCalls"))
      setDvsCalls(getSession("dvsCalls"))
    }
    if (isPageLoaded) {
      // sessionStorage.setItem("loadAuth", false)
      navigation("/",{ replace: true})
    }
  }, [])

  const callDVSAPI = () => {
    setIsLoading(true);
    let verificationId = {}
    if (Object.keys(IDVDocResponse).length > 0 || validateIDVResponse.verificationId) {
      verificationId = {
        verificationId: IDVDocResponse.verificationId || validateIDVResponse.verificationId,
      }
    }
    const payload = {
      "idvInfoInput": {
        "personalInfoInput": {
          ...profileInputs,
          ...verificationId,
          counter: getSession("dvsCalls") - 1,
          scanType: "Digital",
          referenceId: validateIDVResponse.referenceId,
          clientCode: validateIDVResponse.clientCode
        },
        "documentInfoInput": [
          {
            idType: selectIdType,
            ...documentInputs,
          }
        ]
      }
    };
    //console.log("payload", payload, profileInputs)
    validateIDVDocument(payload)(dispatch)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.overallResult === "REJECT") {
            dispatch(saveIDVDocResponse(response.data))
            if (getSession("dvsCalls") === 1 || dvsCalls === 1) {
              navigation("/error",{ replace: true })
            } else {
              // let _message = `Please check that the details below match your ID exactly. You have ${getSession("dvsCalls")} attempts left.`;
              // const attempts = getSession("dvsCalls");
              // const message = idSelection.idvDvsAttempts.attemptsLeftTxt.replace("#countOfAttempts#", attempts)
              setDvsAttempts(prevState => ({ ...prevState, status: true }));
              setSession("dvsCalls", getSession("dvsCalls") - 1);
              setDvsCalls(dvsCalls - 1)
              setIsLoading(false);
            }
          } else {
            dispatch(saveIDVDocResponse(response.data))
            setSession("dvsCalls", 3)
            setDvsCalls(3)
            if (getSession("gotoUrl")) window.location = getSession("gotoUrl")
            else navigation("/confirm-identity",{ replace: true});
          }
        } else {
          const eKay = "validateIDVDocument";
          const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
          const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKay] + serverErrors[eKay][err].errRefNo;
          //console.log("validateIDVDocument : serverErrors, refCode", serverErrors[eKay][err], refCode)
          dispatch(setCommonError({ ...serverErrors[eKay][err], refCode }))
          if (serverErrors[eKay][err] && (serverErrors[eKay][err].code === "err_422_2100" ||
            serverErrors[eKay][err].redirectTo === "_gotoUrl")) {
            setTimeout(() => {
              window.location = getSession("gotoUrl");
            }, 5000);
          } else {
            navigation("/error",{ replace: true });
          }
        }
      })
      .catch((error) => {
        navigation("/error",{ replace: true })
      });
  }

  // updating analytics on every event within the document
  const updateAnalyticsDataFunc = (eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "validation issue",
        "eventCategory": "",
        "eventName": eventName
      },
      "attributes": {}
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  },[])

  // handling click events
  const handleOnClick = (id, eventName, editName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": eventName
      },
      "attributes": {}
    })
    if (id === "find" || id === "close") {
      if (id === "close") inputRef.current["cardNumber"].focus()
      toggleModal();
    }
    if (id === "agree") {
      //console.log("feilds", { ...profileInputs, ...documentInputs })
      if (!validateIDDetails()) return
      callDVSAPI()
    }
    if (id === "verify") {
      navigation("/confirm-identity",{ replace: true })
    }
    if (id === "changeOfName") { console.log("changeOfName") }
    if (id === "cancelChangeOfName") {
      console.log("cancelChangeOfName")
      const { personalDetails } = validateIDVResponse;
      setProfileInputs(prevState => ({
        ...prevState,
        ...personalDetails
      }))
      setIsProfileEdited(false)
    }
    if (id === "edit") {
      window.scrollTo(0, 0);
      toggleEditModal();
    }
    if (id === "edit" || id === "remove") {
      console.log("remove");
      if (id === "remove") setRemoveModal()
      setEditableName({ key: editName, title: editInputs[editName], value: profileInputs[editName] });
    }
    if (id === "removeMN") {
      console.log(id, eventName, " == clicked")
      setProfileInputs(prevState => ({
        ...prevState,
        middleName: ""
      }))
      setIsNameUpdated({
        status: true,
        desc: `Your ${editbleName.title} will be updated once your ID is verified.`,
        icon: "Success"
      });
      setIsProfileEdited(true)
    }
    if (id === "cancelMN") { console.log(id, eventName, "== clicked") }
  }

  useEffect(() => {
    const { personalDetails } = validateIDVResponse;
    const authenicatedFlag = (personalDetails && Object.keys(personalDetails).length > 0)
    setIsAuthenticated(authenicatedFlag)
    if (!authenicatedFlag) {
      let keys = {};
      if (isSingleName) {
        keys = idSelection.mononymous;
      }
      if (!isSingleName) {
        keys = idSelection.profile;
      }
      setProfileInputs(keys)
    }
    if (authenicatedFlag) {
      setProfileInputs({ ...personalDetails})
    }
  }, [isSingleName])

  const handleOnChange = (e, type, id) => {
    const { name, value } = e.target;
    const newValue = name === "idType" ? idSelection.idTypeLabel[value] : value;
    if (name === "idType") {
      setDocumentKeys(importInputKeys(value))
      // setSelectIdType(newValue)
      let data = idSelection[value];
      if (value === "AUSTRALIAN_PASSPORT") {
        data["issuingCountry"] = "AUS";
      } else if (value === "INTERNATIONAL_PASSPORT") {
        data["issuingCountry"] = "";
      } else {
        delete data["issuingCountry"];
      }
      setExpiryDate({ day: "", month: "", year: "" })
      setDocumentInputs(data)
    }
    if (type === "checkbox") {
      setIsSingleName(e.target.checked)
      setSelectIdType("")
      setDocumentKeys([])
      setExpiryDate({ day: "", month: "", year: "" })
      setDateOfBirth({ day: "", month: "", year: "" })
    } else {
      if (id === "idType") {
        setSelectIdType(newValue)
      } else if (id === "profile") {
        setProfileInputs(prevState => ({
          ...prevState,
          [name]: newValue
        }))
      } else {
        setDocumentInputs(prevState => ({
          ...prevState,
          [name]: newValue.toUpperCase()
        }))
      }
    }
  }

  const handleOnBlur = (e, type, id) => {
    if (focusErrElement) setFocusErrElement(false)
    let { name, value } = e.target;
    value = name === "idType" ? idSelection.idTypeLabel[value] : value
    let error = {};
    if (id === "profile") {
      error = validations(name, value, profileInputs)
    } else {
      error = validations(name, value, documentInputs, selectIdType)
    }
    if (error && error.msg && window.digitalData) {
      setErrors(prevState => ({
        ...prevState,
        [name]: error.msg
      }))
      updateAnalyticsDataFunc(error.msg)
    } else {
      setErrors(prevState => ({
        ...prevState,
        [name]: undefined
      }))
      // let err = errors;
      // delete err[name];
      // setErrors(err);
    }
  }

  const validateIDDetails = () => {
    setFocusErrElement(true)
    let isValid = false;
    let data = {};
    !isAuthenticated && Object.keys(profileInputs).forEach(key => {
      const error = validations(key, profileInputs[key], profileInputs)
      data[key] = error && error.msg
    })
    Object.keys(documentInputs).forEach(key => {
      const error = validations(key, documentInputs[key], documentInputs, selectIdType)
      data[key] = error && error.msg
    })
    if (profileInputs.hasOwnProperty("middleName") && profileInputs["middleName"] === "" && profileInputs["middleName"].trim() === "") {
      delete data["middleName"]
    }
    if (selectIdType.trim() === "") {
      const error = validations("idType", selectIdType)
      data["idType"] = error && error.msg
    }
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        delete data[key];
      }
    })
    setErrors(data)
    //console.log("profileInputs",profileInputs,documentInputs,errors,data)
    if (Object.keys(data).length === 0) isValid = true
    return isValid
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      let controlObj = { ...errors };
      Object.keys(controlObj).forEach(
        (key) => (controlObj[key] === undefined || controlObj[key] === null) && delete controlObj[key]
      );
      if (focusErrElement) {
        if (Object.keys(controlObj).length > 0) {
          inputRef.current &&
            inputRef.current[Object.keys(controlObj)[0]].focus();
        }
      }
    }
  }, [errors, focusErrElement]);

  const FooterContent = () => {
    return (
      <ActionButton
        label='Got it'
        variant='HighEmphasis'
        element='button'
        onClick={() =>
          handleOnClick("close", "Got it")
        }
        developmentUrl={window.location.origin + "/able-sprites.svg"}
      />
    );
  };

  const customDateChange = (value, name, id) => {
    if (name === "dob") {
      setDateOfBirth(value);
    } else {
      setExpiryDate(value)
    }
  };

  const customDateBlur = (value, name, id) => {
    const day = value.day && value.day.length === 1 ? +"0" + value.day : value.day;
    const month = value.month && value.month.length === 1 ? +"0" + value.month : value.month;
    const year = value.year;
    const dateValue = day + "/" + month + "/" + year;
    let error = {};
    if (name === "dob") {
      setProfileInputs(prevState => ({
        ...prevState,
        [name]: dateValue
      }))
    } else {
      setDocumentInputs(prevState => ({
        ...prevState,
        [name]: dateValue
      }))
    }
    if ((day && month && year) || (day==="" && month==="" && year==="")) {
      if (id === "profile") {
        error = validations(name, dateValue, profileInputs)
      } else {
        error = validations(name, dateValue, documentInputs, selectIdType)
      }
    }
    if (error && error.msg && window.digitalData) {
      setErrors(prevState => ({
        ...prevState,
        [name]: error.msg
      }))
      updateAnalyticsDataFunc(error.msg)
    } else {
      setErrors(prevState => ({
        ...prevState,
        [name]: undefined
      }))
      // let err = errors;
      // delete err[name];
      // setErrors(err);
    }
  };

  const modalContentOnClose = (id) => {
    if (id === "cardNumber") inputRef && inputRef.current && inputRef.current["cardNumber"].focus()
    else buttonRef && buttonRef.current && buttonRef.current[editbleName.key].focus();
  }

  const BodyContentEdit = () => {
    const [value, setValue] = useState(editbleName.value.trim());
    const [err, setErr] = useState(null);
    const events = {
      onChange: (e) => {
        setValue(e.target.value);
      },
      onBlur: (e) => {
        let error = validations(editbleName.key, value)
        if (error && error.msg) {
          setErr(error.msg)
        } else {
          setErr(null)
        }
      },
    };

    const handleOnModelClick = (id, eventName) => {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "click",
          "eventType": "clickTrack",
          "eventCategory": "buttonClick",
          "eventName": eventName
        },
        "attributes": {}
      })

      if (id === "cancel") {
        toggleEditModal()
      } else {
        if (profileInputs[editbleName.key].trim() !== value && value.trim().length > 0) {
          let error = validations(editbleName.key, value)
          if (error && error.msg) {
            setErr(error.msg)
            updateAnalyticsDataFunc(error.msg)
            return
          }
          setProfileInputs(prevState => ({
            ...prevState,
            [editbleName.key]: value
          }))
          setIsProfileEdited(true)
          setIsNameUpdated({
            status: true,
            desc: `Your ${editbleName.title} will be updated once your ID is verified.`,
            icon: "Success"
          });
        }
        toggleEditModal()
      }
    };

    return (
      <React.Fragment>
        <TextStyle>Enter new {editbleName.title}</TextStyle>
        <CustomSpacing top="spacing3x">
          <TextField
            id={editbleName.key}
            name={editbleName.key}
            label={`${editbleName.key === "dob" ? 'Date of birth' : 'Name'}`}
            value={value}
            invalid={err ? true : false}
            invalidInputText={err}
            developmentUrl={window.location.origin + "/able-sprites.svg"}
            events={events}
            ref={e => inputRef.current[editbleName.key] = e}
          />
        </CustomSpacing>
        <TextStyle className="able-ModalContent--Compact_footer">
          <ActionButton
            label='Cancel'
            variant='MediumEmphasis'
            element='button'
            onClick={() =>
              handleOnModelClick("cancel", "Cancel")
            }
            developmentUrl={window.location.origin + "/able-sprites.svg"}
          />
          <ActionButton
            label='OK'
            variant='HighEmphasis'
            element='button'
            onClick={() =>
              handleOnModelClick("ok", "OK")
            }
            developmentUrl={window.location.origin + "/able-sprites.svg"}
          />
        </TextStyle>
      </React.Fragment>
    );
  };

  const sanitizeValue = (value) => {
    return !validations("isEmpty",value)
  }

  return (
    <PageContent title={title}>
      {isLoading ? <Loader />
        :
        <Grid padding={false}>
          <Column cols={12}>
            <ModalContent
              variant="Compact"
              title="Card number"
              bodyContent={getSampleImage(documentInputs.stateOfIssue)}
              footerContent={<FooterContent />}
              isShowing={modalIsOpen}
              setHideDialog={toggleModal}
              className="able-ModalContent"
              onClose={() => modalContentOnClose("cardNumber")}
              developmentUrl={window.location.origin + "/able-sprites.svg"}
            />
            <ModalContent
              variant="Compact"
              title={`Edit ${editbleName.title}`}
              bodyContent={<BodyContentEdit />}
              footerContent={false}
              isShowing={isEditModel}
              setHideDialog={toggleEditModal}
              className="able-ModalContent able-editbleName"
              onClose={() => modalContentOnClose("editModel")}
              developmentUrl={window.location.origin + "/able-sprites.svg"}
            />
            <ModalDialogCriticalDecision
              isShowing={removeModalIsOpen}
              className="able-fonts"
              setHideDialog={setRemoveModal}
              title='Remove middle name?'
              description='Are you sure you want to remove your middle name?'
              stackButtonOnVXS={false}
              preferredButtonLabel='Yes'
              preferredButtonEvents={{ onClick: () => handleOnClick("removeMN", "Yes, Remove middle name") }}
              secondaryButtonLabel='Cancel'
              secondaryButtonEvents={{ onClick: () => handleOnClick("cancelMN", "Cancel") }}
              developmentUrl={window.location.origin + "/able-sprites.svg"}
              focusOnCloseRef={buttonRef.current[editbleName.key]}
            />
            <PageTitle ref={titleRef} title={title} focusTitle={true} bottom="spacing3x" />
            <TextStyle alias="HeadingC">Your ID details</TextStyle>
            { isAuthenticated ?
              <CustomSpacing top="spacing1x">
                <InlineLink>
                  <TextStyle>
                    For security reasons, you can only edit either your given name or family name. If you need to edit more than one of these details, you can &nbsp;
                    <a className="cnsbColor" rel="noreferrer" onClick={() => handleOnClick("changeOfName", "request a change of name")}>request a change of name</a>.
                  </TextStyle>
                </InlineLink>
                <CustomSpacing top="spacing1x">
                  <TextStyle>
                    Please check your details match your ID document exactly.
                  </TextStyle>
                </CustomSpacing>
              </CustomSpacing>
              :
              <CustomSpacing top="spacing1x">
                <TextStyle>
                  Please enter your details exactly as they appear on your ID document.
                </TextStyle>
              </CustomSpacing>
            }
            {dvsAttempts.status ?
              <CustomSpacing top="spacing3x">
                <MessageInline developmentUrl={window.location.origin + '/able-sprites.svg'} variant="Error">
                  <TextStyle alias="Label">The ID details you entered do not match the database</TextStyle>
                  <TextStyle>Please review your details and ensure they match your ID document exactly. </TextStyle>
                  <CustomSpacing top="spacing1x"/>
                  <TextStyle>You have {dvsCalls} of 3 attempts remaining. </TextStyle>
                </MessageInline>
              </CustomSpacing>
              : null
            }
            {
              isNameUpdated.status ?
              <CustomSpacing top="spacing3x">
                <MessageInline
                  variant={isNameUpdated.icon}
                  developmentUrl={
                    window.location.origin + "/able-sprites.svg"
                  }
                >
                  <TextStyle alias="FinePrintA">Your {editbleName.title} will be updated to your account once your ID is verified in next step. </TextStyle>
                  <CustomSpacing top="spacing1x"/>
                  <TextStyle alias="FinePrintA">You can still cancel this name change request. Your ID will be verified with your existing name in our system.</TextStyle>
                  <CustomSpacing top="spacing1x" />
                  <InlineLink>
                    <TextStyle alias="FinePrintA">
                      <a className="cnsbColor" rel="noreferrer" onClick={() => handleOnClick("cancelChangeOfName", "Cancel name change request")}>Cancel name change request</a>.
                    </TextStyle>
                  </InlineLink>
                </MessageInline>
              </CustomSpacing>
              : null
            }
            {
              isAuthenticated ?
                <React.Fragment>
                  <CustomSpacing top="spacing3x">
                    <CustomSpacing bottom="spacing1x">
                      <TextStyle alias="Label">Given name</TextStyle>
                    </CustomSpacing>
                    <TextStyle>{profileInputs["firstName"]}</TextStyle>
                    {
                      (!isProfileEdited && validateIDVResponse.pdEditable) ?
                        <ActionButton
                          developmentUrl={
                            window.location.origin + "/able-sprites.svg"
                          }
                          element="button"
                          label="Edit Given name"
                          to="/"
                          icon="Edit"
                          type="button"
                          variant="LowEmphasis"
                          onClick={() =>
                            handleOnClick("edit", "Edit Given name", "firstName")
                          }
                          elementRef={e => buttonRef.current["firstName"] = e}
                        /> : null
                    }
                  </CustomSpacing>
                  <CustomSpacing top="spacing3x">
                    <CustomSpacing bottom="spacing1x">
                      <TextStyle alias="Label">Middle name</TextStyle>
                    </CustomSpacing>
                    <TextStyle>{profileInputs["middleName"]}</TextStyle>
                    <TextStyle element="div" className="buttonGroup">
                      {
                        (!isProfileEdited && validateIDVResponse.pdEditable) ?
                          <React.Fragment>
                            <ActionButton
                              developmentUrl={
                                window.location.origin + "/able-sprites.svg"
                              }
                              element="button"
                              label={`${sanitizeValue(profileInputs.middleName) ? "Edit Middle name" : "Add Middle name"}`}
                              icon="Edit"
                              type="button"
                              variant="LowEmphasis"
                              onClick={() =>
                                handleOnClick("edit", `${sanitizeValue(profileInputs.middleName) ? "Edit Middle name" : "Add Middle name"}`, "middleName")
                              }
                              elementRef={e => buttonRef.current["middleName"] = e}
                            />
                            {
                              sanitizeValue(profileInputs.middleName) &&
                              <ActionButton
                                developmentUrl={
                                  window.location.origin + "/able-sprites.svg"
                                }
                                element="button"
                                label="Remove"
                                icon="Delete"
                                type="button"
                                variant="LowEmphasis"
                                className="able-ActionButton--destructive"
                                onClick={() =>
                                  handleOnClick("remove", "Remove", "middleName")
                                }
                              />
                            }
                          </React.Fragment> : null
                      }
                    </TextStyle>
                  </CustomSpacing>
                  <CustomSpacing top="spacing3x">
                    <CustomSpacing bottom="spacing1x">
                      <TextStyle alias="Label">Family name</TextStyle>
                    </CustomSpacing>
                    <TextStyle>{profileInputs["familyName"]}</TextStyle>
                    {
                      (!isProfileEdited && validateIDVResponse.pdEditable) ?
                        <ActionButton
                          developmentUrl={
                            window.location.origin + "/able-sprites.svg"
                          }
                          element="button"
                          label="Edit Family name"
                          to="/"
                          icon="Edit"
                          type="button"
                          variant="LowEmphasis"
                          onClick={() =>
                            handleOnClick("edit", "Edit Family name", "familyName")
                          }
                          elementRef={e => buttonRef.current["familyName"] = e}
                        /> : null
                    }
                  </CustomSpacing>
                  <CustomSpacing top="spacing3x">
                    <CustomSpacing bottom="spacing1x">
                      <TextStyle alias="Label">Date of birth</TextStyle>
                    </CustomSpacing>
                    <TextStyle>{profileInputs["dob"]}</TextStyle>
                    {
                      (!isProfileEdited && validateIDVResponse.dobEditable) ?
                        <ActionButton
                          developmentUrl={
                            window.location.origin + "/able-sprites.svg"
                          }
                          element="button"
                          label="Edit Date of birth"
                          to="/"
                          icon="Edit"
                          type="button"
                          variant="LowEmphasis"
                          onClick={() =>
                            handleOnClick("edit", "Edit Date of birth", "dob")
                          }
                          elementRef={e => buttonRef.current["dob"] = e}
                        /> : null
                    }
                  </CustomSpacing>
                </React.Fragment>
                :
                <React.Fragment>
                  <CustomSpacing top="spacing3x" bottom="spacing1x">
                    <Checkbox
                      label="I have a single name (mononymous) on my ID"
                      name="singleName"
                      value="singleName"
                      checked={isSingleName}
                      events={{
                        onChange: (e) => handleOnChange(e, "checkbox")
                      }}
                    />
                  </CustomSpacing>
                  {
                    isSingleName ?
                      <CustomSpacing bottom="spacing3x">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="Name"
                          value={profileInputs.firstName}
                          helpText="Enter exactly as it appears on your ID, including spaces, apostrophes, hyphens, and diacritical marks or accents."
                          invalid={errors.firstName ? true : false}
                          invalidInputText={errors.firstName}
                          developmentUrl={window.location.origin + "/able-sprites.svg"}
                          onChange={(e) => handleOnChange(e, "text", "profile")}
                          onBlur={(e) => handleOnBlur(e, "text", "profile")}
                          ref={e => inputRef.current["firstName"] = e}
                        />
                      </CustomSpacing>
                      :
                      <React.Fragment>
                        <CustomSpacing bottom="spacing3x">
                          <TextField
                            id="firstName"
                            name="firstName"
                            label="Given name"
                            value={profileInputs.firstName}
                            invalid={errors.firstName ? true : false}
                            invalidInputText={errors.firstName}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            onChange={(e) => handleOnChange(e, "text", "profile")}
                            onBlur={(e) => handleOnBlur(e, "text", "profile")}
                            ref={e => inputRef.current["firstName"] = e}
                          />
                        </CustomSpacing>
                        <CustomSpacing bottom="spacing3x">
                          <TextField
                            id="middleName"
                            name="middleName"
                            label="Middle name"
                            value={profileInputs.middleName}
                            invalid={errors.middleName ? true : false}
                            invalidInputText={errors.middleName}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            onChange={(e) => handleOnChange(e, "text", "profile")}
                            onBlur={(e) => handleOnBlur(e, "text", "profile")}
                            ref={e => inputRef.current["middleName"] = e}
                          />
                        </CustomSpacing>
                        <CustomSpacing bottom="spacing3x">
                          <TextField
                            id="familyName"
                            name="familyName"
                            label="Family name"
                            value={profileInputs.familyName}
                            invalid={errors.familyName ? true : false}
                            invalidInputText={errors.familyName}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            onChange={(e) => handleOnChange(e, "text", "profile")}
                            onBlur={(e) => handleOnBlur(e, "text", "profile")}
                            ref={e => inputRef.current["familyName"] = e}
                          />
                        </CustomSpacing>
                      </React.Fragment>
                  }
                  <DayMonthYearGroup
                    developmentUrl={window.location.origin + "/able-sprites.svg"}
                    label='Date of birth'
                    helpText="Use the format DD MM YYYY. Enter numbers only."
                    invalidInputText={errors.dob}
                    maxYear={currentYear - 10}
                    minYear={currentYear - 120}
                    required={true}
                    isInvalid={errors.dob ? true : false}
                    externalOnChange={(value) => customDateChange(value, "dob", "profile")}
                    externalOnBlur={(value) => customDateBlur(value, "dob", "profile")}
                    value={dateOfBirth}
                    ref={e => inputRef.current["dob"] = e}
                  />
                </React.Fragment>
            }
            <CustomSpacing top="spacing5x" bottom="spacing5x">
              <RadioGroup
                groupLabel='Choose an ID document to verify'
                name='idType'
                id='idType'
                variant='Comfortable'
                developmentUrl={window.location.origin + '/able-sprites.svg'}
                invalid={errors.idType ? true : false}
                legendRef={radioGroupRef}
                errorText={errors.idType}
              >
                {
                  idSelection.idType && idSelection.idType.length > 0 && idSelection.idType.map((idType, index) => {
                    return (
                      <RadioButton
                        key={index}
                        developmentUrl={window.location.origin + '/able-sprites.svg'}
                        value={idType.value}
                        label={idType.label}
                        name={idType.value}
                        events={
                          {
                            onChange: e => { handleOnChange(e, "radio", "idType") },
                            onBlur: e => { handleOnBlur(e, "radio", "idType") }
                          }
                        }
                        checked={idType.label === selectIdType ? true : false}
                        ref={(e) => (inputRef.current["idType"] = e)}
                      />
                    )
                  })
                }
              </RadioGroup>
              {
                BAMBOO_IDV_UNAVAILABLE && documentKeys.length > 0 &&
                <CustomSpacing top="spacing3x">
                  <MessageInline
                    description='Australian Passport is temporarily unavailable. Please choose another ID type, or visit your nearest Telstra store.'
                    variant='Attention'
                    developmentUrl='./able-sprites.svg'
                  />
                </CustomSpacing>
              }
              {
                documentKeys.length > 0 && documentKeys.map((key, index) => {
                  return (
                    <CustomSpacing top="spacing3x" key={index}>
                      {
                        key.type === "select" &&
                        <Select
                          id={key.id}
                          name={key.id}
                          label={key.title}
                          options={key.options}
                          value={documentInputs[key.id]}
                          invalid={errors[key.id] ? true : false}
                          invalidMessage={errors[key.id]}
                          developmentUrl={
                            window.location.origin + "/able-sprites.svg"
                          }
                          events={
                            {
                              onChange: e => { handleOnChange(e, key.type) },
                              onBlur: e => { handleOnBlur(e, key.type) }
                            }
                          }
                          ref={(e) => (inputRef.current[key.id] = e)}
                        />
                      }
                      {
                        key.type === "date" &&
                        <DayMonthYearGroup
                          developmentUrl={window.location.origin + "/able-sprites.svg"}
                          label={key.title}
                          helpText={key.helpText}
                          invalidInputText={errors[key.id]}
                          maxYear={currentYear + 10}
                          minYear={currentYear}
                          required={true}
                          isInvalid={errors[key.id] ? true : false}
                          externalOnChange={(value) => customDateChange(value, "expiryDate")}
                          externalOnBlur={(value) => customDateBlur(value, "expiryDate")}
                          value={expiryDate}
                          ref={e => inputRef.current[key.id] = e}
                        />
                      }
                      {
                        key.type === "text" &&
                        <CustomSpacing>
                          <TextField
                            id={key.id}
                            name={key.id}
                            label={key.title}
                            value={documentInputs[key.id]}
                            invalid={errors[key.id] ? true : false}
                            invalidInputText={errors[key.id]}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            events={
                              {
                                onChange: e => { handleOnChange(e, key.id) },
                                onBlur: e => { handleOnBlur(e, key.id) }
                              }
                            }
                            ref={e => inputRef.current[key.id] = e}
                          />
                          {key.showCardRef &&
                            <CustomSpacing top="spacing1x">
                              <ActionButton
                                developmentUrl={
                                  window.location.origin + "/able-sprites.svg"
                                }
                                element="button"
                                label="Can't find your card number?"
                                to="/"
                                className="noIcon"
                                type="button"
                                variant="LowEmphasis"
                                onClick={() =>
                                  handleOnClick("find", "Can't find your card number?")
                                }
                              />
                            </CustomSpacing>
                          }
                        </CustomSpacing>
                      }
                    </CustomSpacing>
                  )
                })
              }
            </CustomSpacing>
            <TextStyle alias="HeadingC" element="h3">Permission to verify your ID</TextStyle>
            <CustomSpacing top="spacing1x" bottom="spacing1x">
              <TextStyle>By clicking on the “Agree & Continue” button, you agree to have your ID checked with the issuer of that ID.</TextStyle>
            </CustomSpacing>
            <TextStyle>Telstra won't store your information, except your name and date of birth, if required.</TextStyle>
            <CustomSpacing top="spacing4x">
              <Grid className="buttonGroup">
                <ActionButton
                  developmentUrl={
                    window.location.origin + "/able-sprites.svg"
                  }
                  element="button"
                  label="Agree and continue"
                  to="/"
                  type="button"
                  variant="HighEmphasis"
                  className="fullwidth"
                  onClick={() =>
                    handleOnClick("agree", "Agree and continue")
                  }
                />
                <CustomSpacing top="spacing4x"></CustomSpacing>
                <ActionButton
                  developmentUrl={
                    window.location.origin + "/able-sprites.svg"
                  }
                  element="button"
                  label="Choose another way to verify"
                  to="/"
                  icon="ChevronLeft"
                  type="button"
                  variant="LowEmphasis"
                  className="fullwidth"
                  onClick={() =>
                    handleOnClick("verify", "Choose another way to verify")
                  }
                />
              </Grid>
            </CustomSpacing>
          </Column>
        </Grid>
      }
    </PageContent>
  );
}