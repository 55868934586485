import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import AppContent from "../AppContentUser.tsx";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { validateIDVRequest } from '../../redux/api';
import { setSession, getSession } from "../../helpers";
import { serverErrors, errorRefId } from "../../helpers/messages";
import { saveValidateIDVResponse, setPageLoaded, setCommonError } from '../../redux/verifyIdentifySlice';
import { useAuth } from "react-oidc-context";

export default function PageAuthenticate() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [title] = useState("Authenticate");
  let mounted = true;
  const auth = useAuth();
  
  useEffect(() => {
    if (!getSession("loadAuth")) {
      auth.signinRedirect()
    }
    if (mounted && getSession("loadAuth")) {
      validateIDVRequestCall()
    }
    mounted = false;
  }, [getSession("loadAuth")])

  const validateIDVRequestCall = () => {
    dispatch(setPageLoaded(false))
    validateIDVRequest()(dispatch)
    .then((response) => {
      //console.log(response)
      if (response.status === 200) {
        dispatch(saveValidateIDVResponse(response.data))
        navigation("/confirm-identity",{ replace: true});
      } else {
        const eKey = "validateIDVRequest";
        const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
        const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKey] + serverErrors[eKey][err].errRefNo;
        dispatch(setCommonError({ ...serverErrors[eKey][err], refCode }))
        //console.log("validateIDVRequest: serverErrors, refCode",serverErrors[eKey][err], refCode)
        if (serverErrors[eKey][err] && serverErrors[eKey][err].redirectTo === "_gotoUrl") {
          window.location = getSession("gotoUrl");
        } else {
          // navigation("/confirm-identity",{ replace: true});
          navigation("/error",{ replace: true });
        }
      }
    })
    .catch((error) => {
      //console.log(error)
      navigation("/error",{ replace: true });
    });
  }
  
  return (
    <PageContent title={title}>
      <Loader />
    </PageContent>
  );
}