import * as React from 'react';

import { ApiService } from '../services/ApiServiceUser.tsx';
import AuthService from '../services/AuthServiceUser.tsx';

// import App from './App';

export default class AppContent extends React.Component<any, any> {
  public authService: AuthService;
  public apiService: ApiService;
  private shouldCancel: boolean;

  constructor(props: any) {
    super(props);

    this.authService = new AuthService();
    this.apiService = new ApiService();
    this.state = { user: {}, api: {} };
    this.shouldCancel = false;
  }

  public componentDidMount() {
    this.authService.getUser().then(user => {
      if (!user) {
        this.login();
      } else {
        if(this.props.renew) {
          this.renewToken();
        } 
        this.setState({isRegId:false})
        sessionStorage.setItem('AuthData', JSON.stringify(user));
      }
    });
  }

  public login = () => {
    if(this.props.isNewUser===true){
      this.authService.register();
    } else {
      this.authService.login();
    }
  };

  public callApi = () => {
    this.apiService
      .callApi()
      .then(data => {
        this.setState({ api: data.data });
        //console.log('Api return successfully data, check in section - Api response');
      })
      .catch(error => {
        //console.log(error);
      });
  };

  public componentWillUnmount() {
    this.shouldCancel = true;
    this.setState({isRegId:false})
  }

  public renewToken = () => {
    this.authService
      .renewToken()
      .then(user => {
        //console.log('Token has been sucessfully renewed. :-)');
        this.getUser();
      })
      .catch(error => {
        //console.log(error);
      });
  };

  public logout = () => {
    this.authService.logout();
  };

  public getUser = () => {
    
    this.authService.getUser().then(user => {
      if (user) {
        //console.log('User has been successfully loaded from store.');
      } else {
        //console.log('You are not logged in.');
      }

      if (!this.shouldCancel) {
        this.setState({ user });
      }
    });
  };


  public render() {
    
    return (
      <>
        {/* <App />           */}
      </>
    );
  }
}
