import React,{useState,useEffect} from "react";
import PageContent from "../PageContent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAnalyticsData } from '../../helpers/analytics';
import { serverErrors, errorRefId } from '../../helpers/messages';
import { Spacing,TextStyle,ActionButton } from "@able/react";
import { setSession,removeSessionItem } from "../../helpers";
import config from "../../helpers/config";
import Loader from "../../components/Loader";
import { CidButton } from './CidButton.tsx';
import CustomSpacing from "../../components/Spacing";
const title = "Verify identity with ConnectID";

export default function PageVerifyDigitalID() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  // const [cidStatus] = useState(localParams.cid_status);
  const cidStatus=[]
  const claimsList = ["name", "given_name", "middle_name", "family_name", "phone_number", "email"];
  // const claimsList = ["name", "given_name", "middle_name", "family_name", "phone_number", "email", "address", "birthdate", "txn", "over16", "over18", "over21", "over25", "over65", "beneficiary_account_au", "beneficiary_account_au_payid", "beneficiary_account_international"];

  useEffect(() => {
    setTimeout(()=>{setIsLoading(false)},1000)
  },[])

  const handleOnProceed = (authorisationServerId) => {
    setSession("par_authorisationServerId",authorisationServerId)
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": "Verify with ConnectID"
      },
      "attributes":{}
    })
    setIsLoading(true)
    const payload = {
      "authorisationServerId": authorisationServerId,
      "essentialClaims": claimsList,
    }
    // IDPSelectorAuth(payload)(dispatch)
    //   .then((response) => {
    //     if (response.message || !response.status || response.status === 401) {
    //       setSession("tokenExpired", true);
    //       // dispatch(setLocalParams({ ...localParams, tokenStatus: 401 }))
    //     } else {
    //       setSession("tokenExpired", false)
    //     }
    //     if (response.status === 200) {
    //       setSession("par_state",response.data.state)
    //       setSession("par_verifier",response.data.codeVerifier)
    //       setSession("par_nonce",response.data.nonce)
    //       window.location = response.data.authUrl
    //     } else {
    //       let err = null;
    //       let refCode = null;
    //       if(response.status === 500){
    //         err = "err_default";
    //       } else if(response.status === 422){
    //         err = "err_userToken";
    //       } else {
    //         err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
    //       }
    //       refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId["cidPar"] + serverErrors[err].errRefNo;
    //       dispatch(setCommonError({...serverErrors[err], refCode}))
    //       history.push("/error")
    //     }
    //   })
    //   .catch((error) => {
    //     history.push("/error")
    //   });
  }

  // handling click events
  const handleOnClick = (id, eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": eventName
      },
      "attributes":{}
    })
    
    if (id === "back") {
      removeSessionItem("par_state")
      removeSessionItem("par_verifier")
      removeSessionItem("par_nonce")
      removeSessionItem("par_authorisationServerId")
      removeSessionItem("cid_code")
      navigation("/",{ replace: true })
    }
  }

  return (
    <PageContent title={title} type="connectID">
      {isLoading ? <Loader /> :
        <div>
          {
            Object.keys(cidStatus).length > 0 ?
            <CustomSpacing bottom="spacing4x">
              <TextStyle alias="HeadingB">ConnectID verification done successfully</TextStyle>
              <CustomSpacing top="spacing4x" />
              <TextStyle>Given name: { cidStatus.given_name }</TextStyle>
              <TextStyle>Family name: { cidStatus.family_name }</TextStyle>
              <TextStyle>Phone Number: { cidStatus.phone_number }</TextStyle>
              <TextStyle>Email: { cidStatus.email }</TextStyle>
            </CustomSpacing>
            :
            <CidButton
              onProceed={(authorisationServerClicked) => handleOnProceed(authorisationServerClicked)}
              claims={claimsList} participantsUri={config.idpUrl}
            />
          }
          <CustomSpacing top="spacing5x" />
          <ActionButton
            developmentUrl={window.location.origin + '/able-sprites.svg'}
            element="button"
            label="Back"
            to="/"
            type="button"
            variant="MediumEmphasis"
            className="fullwidth"
            onClick={() => handleOnClick("back", "Back")}
          />
        </div>
      }
    </PageContent>
  );
}
