import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Column, Spacing, TextStyle, ActionButton, Picto, SomethingWentWrong104 } from '@able/react';
import PageContent from "../PageContent";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import CustomSpacing from "../../components/Spacing";

export default function PageError(props) {
  const navigation = useNavigate();
  const titleRef = useRef(null);
  const [titleText,setTitleText] = useState("Oops!");
  const {commonError,isPageLoaded} = useSelector((state) => state.verifyIdentify)
  
  useEffect(() => { 
    const ariaHidden = document.getElementById("root");
    ariaHidden.hasAttribute("aria-hidden") && ariaHidden.removeAttribute("aria-hidden");
    document.querySelector("body").style.overflow = "auto";
    if (isPageLoaded) {
      navigation("/",{ replace: true})
    }
  },[])

  useEffect(() => {
    console.log("commonError",commonError)
    if (commonError && commonError.heading) {
      setTitleText(commonError.heading);
    }
    
    updateAnalyticsData(titleText, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "technical issue",
        "eventCategory": (commonError && commonError.code) ? commonError.code : "err_default",
        "eventName": (commonError && commonError.heading) ? commonError.heading : titleText
      },
      "attributes": {}
    })
  }, [commonError]);
  
  return (
    <PageContent title={titleText} className={"whiteBg"}>
      <Grid>
        <Column cols={12}>
          <CustomSpacing bottom="spacing4x" className="errorImgBlock">
            <Picto theme="blue">
              <SomethingWentWrong104 />
            </Picto>
          </CustomSpacing>
          <PageTitle title={titleText} ref={titleRef} focusTitle={true} />
          <TextStyle alias="HeadingC" element="h3">{ commonError.subHeading ? commonError.subHeading : 'Something is not right'}</TextStyle>
          <CustomSpacing top="spacing3x" bottom="spacing1x">
            <TextStyle>
              { commonError.content1 ? commonError.content1 : "We've looked everywhere but cannot complete your request."}
              </TextStyle>
          </CustomSpacing>
            <TextStyle>
              { commonError.content2 ? commonError.content2 : "Please try again."}
            </TextStyle>
        </Column>
      </Grid>
    </PageContent>
  );
}
